/*
@File: Fiva Angular 8 Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Sidemenu CSS
** - Top Navbar CSS
** - Main Content CSS
** - App Email CSS
** - App Email Read CSS
** - App Email Compose CSS
** - App Todo CSS
** - App Calendar CSS
** - App Chat CSS
** - Profile CSS
** - Gallery CSS
** - Login CSS
** - Register CSS
** - Reset Password CSS
** - Forgot Password CSS
** - Lock Screen CSS
** - Error 404 CSS
** - Error 500 CSS
** - Coming Soon CSS
** - Maintenance CSS
** - Not Authorized CSS
** - FAQ CSS
** - Pricing CSS
** - Invoice CSS
** - Timeline CSS
** - Footer CSS
*/
/*================================================
Default CSS
=================================================*/
.h_txex {
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  margin: 3px;
  margin-bottom: 0px;
}

.hide_w_79 {
  width: calc(100% - 85px) !important;
}

.res_table {
  width: 100%;
  padding-bottom: 100px;
  margin-top: 46px;
}

.w_79 {
  width: calc(100% - 280px);
}

body {
  color: #2a2a2a;
  background-color: #fafafa;
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-family: "Nunito", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #2a2a2a;
  font-family: "Nunito", sans-serif;
}

.h_txex {
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  margin: 3px;
  margin-bottom: 0px;
}

a {
  transition: 0.5s;
  outline: 0 !important;
}

p {
  line-height: 1.8;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

button, input {
  outline: 0 !important;
}

.btn-outline-light {
  color: #212529;
}

.tbars {
  background-color: #ffffff;
  z-index: 999999;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 47px;
  width: 100%;
  padding: 10px;
  padding-top: 10px;
  color: white;
}

.s_bar {
  position: fixed;
  top: 10px;
  right: 200px;
  width: 46% !important;
  z-index: 99999;
}

.mshow {
  display: none !important;
}

/*dropdown-css*/
.dropdown-menu {
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  background-color: #ffffff;
  border-color: #eeeeee;
}
.dropdown-menu .form-control {
  border-radius: 5px;
  height: 35px;
}
.dropdown-menu table {
  width: 100%;
  width: 95%;
  margin: 10px;
}

/*progress-css*/
.progress + .progress {
  margin-top: 1rem;
}

/*modal-css*/
.modal p {
  line-height: 1.8;
  color: #727E8C;
}

/*forms*/
.form-check-input {
  margin-top: 0.2rem;
}

.form-control:focus {
  box-shadow: unset;
  border-color: #e1000a;
}

/*================================================
Sidemenu CSS
=================================================*/
.sidemenu-area {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 250px;
  z-index: 9999999;
  transition: 0.5s;
}
.sidemenu-area .sidemenu-header {
  background: #ffffff;
  padding: 0 20px;
  border-bottom: 1px solid #f2f4f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  transition: 0.5s;
}
.sidemenu-area .sidemenu-header .navbar-brand {
  padding: 0;
}
.sidemenu-area .sidemenu-header .navbar-brand span {
  position: relative;
  top: 3px;
  color: #2a2a2a !important;
  margin-left: 10px;
  transition: 0.5s;
  font-size: 25px;
  font-weight: 700;
}
.sidemenu-area .sidemenu-header .burger-menu {
  cursor: pointer;
  transition: 0.5s;
}
.sidemenu-area .sidemenu-header .burger-menu span {
  height: 1px;
  width: 25px;
  background: #2a2a2a;
  display: block;
  margin: 6px 0;
  transition: all 0.5s ease-in-out;
}
.sidemenu-area .sidemenu-header .burger-menu.active span.top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.sidemenu-area .sidemenu-header .burger-menu.active span.middle-bar {
  opacity: 0;
}
.sidemenu-area .sidemenu-header .burger-menu.active span.bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  margin-top: 5px;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu {
  cursor: pointer;
  transition: 0.5s;
  padding-left: 10px !important;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu span {
  height: 1px;
  width: 25px;
  background: #2a2a2a;
  display: block;
  margin: 6px 0;
  transition: all 0.5s ease-in-out;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu.active span.top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu.active span.middle-bar {
  opacity: 0;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu.active span.bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  margin-top: 5px;
}
.sidemenu-area .sidemenu-body {
  max-height: calc(100% - 45px);
  position: relative;
  height: 100%;
  box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.1);
  background: #ffffff;
  overflow: hidden;
}
.sidemenu-area .sidemenu-body .sidemenu-nav {
  padding: 20px 0;
  list-style-type: none;
  margin-bottom: 0;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item-title {
  color: #686868;
  display: block;
  text-transform: uppercase;
  margin-bottom: 7px;
  font-size: 13px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item-title:not(:first-child) {
  margin-top: 20px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link {
  color: #7e7e7e;
  padding: 10px 20px;
  position: relative;
  z-index: 1;
  font-size: 15px;
  font-weight: 500;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .icon {
  color: #2a2a2a;
  transition: 0.5s;
  display: inline-block;
  margin-right: 9px;
  font-size: 18px;
  position: relative;
  top: 2px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .badge {
  background-color: #43d39e;
  color: #ffffff;
  transition: 0.5s;
  margin-left: 7px;
  position: relative;
  top: -1px;
  padding: 4px 7px 4px 6px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.disabled {
  color: #a3a3a3;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.disabled i {
  color: #a3a3a3;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  transition: 0.5s;
  background: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.collapsed-nav-link {
  position: relative;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.collapsed-nav-link::after {
  content: "\e9f9";
  position: absolute;
  right: 15px;
  top: 9px;
  color: #7e7e7e;
  transition: 0.5s;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover {
  background-color: #f9ecef;
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover::before {
  width: 3px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover .icon {
  transform: rotateY(-180deg);
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.active .nav-link {
  background-color: #f9ecef;
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link::before, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.active .nav-link::before {
  width: 3px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link .icon, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.active .nav-link .icon {
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link.collapsed-nav-link::after, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.active .nav-link.collapsed-nav-link::after {
  transform: rotate(-90deg);
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level {
  list-style-type: none;
  padding-left: 30px;
  margin-bottom: 0;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .nav-link {
  color: #7e7e7e;
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 5px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .nav-link .icon {
  color: #2a2a2a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .nav-link::before {
  display: none;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .nav-link.collapsed-nav-link::after {
  transform: rotate(0deg);
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .nav-link:hover {
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .nav-link:hover .icon {
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item.mm-active .nav-link, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item.active .nav-link {
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item.mm-active .nav-link .icon, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item.active .nav-link .icon {
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item.mm-active .nav-link.collapsed-nav-link::after, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item.active .nav-link.collapsed-nav-link::after {
  transform: rotate(-90deg);
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level {
  list-style-type: none;
  padding-left: 15px;
  margin-bottom: 0;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item .nav-link {
  color: #7e7e7e;
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 5px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item .nav-link .icon {
  color: #2a2a2a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item .nav-link::before {
  display: none;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item .nav-link.collapsed-nav-link::after {
  transform: rotate(0deg);
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item .nav-link:hover {
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item .nav-link:hover .icon {
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item.mm-active .nav-link, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item.active .nav-link {
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item.mm-active .nav-link .icon, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item.active .nav-link .icon {
  color: #e1000a;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item.mm-active .nav-link.collapsed-nav-link::after, .sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level .nav-item.active .nav-link.collapsed-nav-link::after {
  transform: rotate(-90deg);
}
.sidemenu-area.toggle-sidemenu-area {
  width: 55px;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-header {
  padding: 0 10px;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-header .navbar-brand {
  display: block !important;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-header .navbar-brand span {
  opacity: 0;
  visibility: hidden;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-header .burger-menu {
  opacity: 0;
  visibility: hidden;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item-title {
  display: none;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .menu-title {
  display: none;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link::after {
  display: none;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .badge {
  display: none;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level {
  padding-left: 20px;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level {
  padding-left: 0;
}
.sidemenu-area:hover.toggle-sidemenu-area {
  width: 250px;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header {
  padding: 0 20px;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .navbar-brand {
  display: flex !important;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .navbar-brand span {
  opacity: 1;
  visibility: visible;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .burger-menu {
  opacity: 1;
  visibility: visible;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item-title {
  display: block;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .nav-link .menu-title {
  display: inline-block;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .nav-link::after {
  display: block;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .nav-link .badge {
  display: inline-block;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .sidemenu-nav-second-level {
  padding-left: 30px;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level {
  padding-left: 15px;
}

/*================================================
Top Navbar CSS
=================================================*/
.top-navbar.navbar {
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
}
.top-navbar.navbar .responsive-burger-menu {
  cursor: pointer;
  transition: 0.5s;
  padding-left: 10px !important;
}
.top-navbar.navbar .responsive-burger-menu span {
  height: 1px;
  width: 25px;
  background: #2a2a2a;
  display: block;
  margin: 6px 0;
  transition: all 0.5s ease-in-out;
}
.top-navbar.navbar .responsive-burger-menu.active span.top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.top-navbar.navbar .responsive-burger-menu.active span.middle-bar {
  opacity: 0;
}
.top-navbar.navbar .responsive-burger-menu.active span.bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  margin-top: 5px;
}
.top-navbar.navbar .nav-search-form {
  position: relative;
  width: 450px;
}
.top-navbar.navbar .nav-search-form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 15px;
  top: 11.3px;
  font-size: 18px;
  color: #a8a8a8;
}
.top-navbar.navbar .nav-search-form .form-control {
  height: 45px;
  border-radius: 30px !important;
  background-color: #fbfbfb;
  border: none;
  color: #2a2a2a;
  padding: 0 15px 0 40px;
  transition: 0.5s;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.top-navbar.navbar .nav-search-form .form-control:focus {
  box-shadow: unset;
  background-color: #eef5f9;
}
.top-navbar.navbar .nav-search-form .form-control:focus::placeholder {
  color: transparent !important;
}
.top-navbar.navbar .nav-search-form .form-control::placeholder {
  color: #a8a8a8;
  transition: 0.5s;
}
.top-navbar.navbar .nav-item {
  margin-left: 5px;
}
.top-navbar.navbar .nav-item .nav-link {
  padding: 0;
  color: #525f80;
  font-weight: 500;
}
.top-navbar.navbar .nav-item .nav-link.dropdown-toggle::after {
  display: none;
}
.top-navbar.navbar .nav-item .nav-link .bx-chevron-down {
  font-size: 18px;
  position: relative;
  display: inline-block;
  top: 3px;
  margin-left: -4px;
  color: #525f80;
}
.top-navbar.navbar .nav-item .nav-link img {
  width: 30px;
  margin-right: 3px;
}
.top-navbar.navbar .nav-item .nav-link .badge {
  position: absolute;
  top: -2px;
  right: -7px;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 12px;
}
.top-navbar.navbar .nav-item .nav-link .badge.badge-secondary {
  background: #e1000a;
  animation: pulse-secondary 2s infinite;
  box-shadow: 0 0 0 rgba(255, 0, 10, 0.9);
}
.top-navbar.navbar .nav-item .nav-link .badge.badge-primary {
  background: #2962ff;
  animation: pulse-primary 2s infinite;
  box-shadow: 0 0 0 rgba(41, 98, 255, 0.9);
}
.top-navbar.navbar .nav-item .nav-link.bx-fullscreen-btn {
  font-size: 22px;
  position: relative;
  cursor: pointer;
  top: 3px;
  margin-right: 5px;
}
.top-navbar.navbar .nav-item .nav-link.bx-fullscreen-btn:hover {
  color: #e1000a;
}
.top-navbar.navbar .nav-item .nav-link.bx-fullscreen-btn.active i::before {
  content: "\ea5d";
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu {
  max-width: 280px;
  position: absolute;
  right: 0;
  left: auto;
  border-radius: 2px;
  margin-top: 20px;
  border: none;
  display: block;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu.show {
  margin-top: 10px;
  opacity: 1;
  visibility: visible;
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px;
  position: relative;
  font-size: 15px;
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item.active, .top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.top-navbar.navbar .nav-item:first-child {
  margin-left: 0;
}
.top-navbar.navbar .nav-item.language-switch-nav-item .nav-link img {
  width: 25px;
  margin-right: 7px;
  position: relative;
  top: -2px;
  border-radius: 2px;
}
.top-navbar.navbar .nav-item.language-switch-nav-item.dropdown .dropdown-menu {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}
.top-navbar.navbar .nav-item.language-switch-nav-item.dropdown .dropdown-menu .dropdown-item {
  padding: 5px 20px;
  font-size: 14.5px;
}
.top-navbar.navbar .nav-item.language-switch-nav-item.dropdown .dropdown-menu .dropdown-item img {
  width: 27px;
  border-radius: 2px;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu {
  width: 350px;
  max-width: 350px;
  border-radius: 5px 5px 0 0;
  padding: 0;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-header {
  border-radius: 5px 5px 0 0;
  background-color: #e1000a;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-header span {
  font-size: 13px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-header span.mark-all-btn {
  cursor: pointer;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item {
  padding: 7px 15px;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  background: #f7f7f7;
  text-align: center;
  color: #e1000a;
  transition: 0.5s;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item .content {
  padding-left: 15px;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item .content span {
  color: #2a2a2a;
  margin-bottom: 1px;
  font-size: 14.5px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item .content p {
  font-size: 12px;
  color: #686868;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item:hover .icon {
  background-color: #e1000a;
  color: #ffffff;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item:nth-child(2) .icon {
  background: rgba(114, 124, 245, 0.1);
  color: #727cf5;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item:nth-child(2):hover .icon {
  background-color: #727cf5;
  color: #ffffff;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item:nth-child(3) .icon {
  background: rgba(16, 183, 89, 0.1);
  color: #10b759;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item:nth-child(3):hover .icon {
  background-color: #10b759;
  color: #ffffff;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item:nth-child(4) .icon {
  background: rgba(255, 51, 102, 0.1);
  color: #f36;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item:nth-child(4):hover .icon {
  background-color: #f36;
  color: #ffffff;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item:nth-child(5) .icon {
  background: rgba(251, 188, 6, 0.1);
  color: #fbbc06;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body .dropdown-item:nth-child(5):hover .icon {
  background-color: #fbbc06;
  color: #ffffff;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid #eeeeee;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-footer .dropdown-item {
  color: #e1000a !important;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item .notification-btn {
  font-size: 20px;
  position: relative;
  margin-right: 7px;
}
.top-navbar.navbar .nav-item .notification-btn i {
  position: relative;
  top: 3px;
  animation: ring 4s 0.7s ease-in-out infinite;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu {
  width: 350px;
  max-width: 350px;
  border-radius: 5px 5px 0 0;
  padding: 0;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-header {
  background-color: #2962ff;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-header span {
  font-size: 13px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-header span.clear-all-btn {
  cursor: pointer;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body .dropdown-item {
  padding: 7px 15px;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body .dropdown-item .figure img {
  width: 35px;
  height: 35px;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body .dropdown-item .content {
  width: 285px;
  padding-left: 12px;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body .dropdown-item .content span {
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body .dropdown-item .content .time-text {
  font-size: 13px;
  color: #686868;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body .dropdown-item .content .sub-text {
  font-size: 11px;
  color: #686868;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body .dropdown-item:hover .icon {
  background-color: #e1000a;
  color: #ffffff;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid #eeeeee;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-footer .dropdown-item {
  color: #2962ff !important;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item .message-btn {
  font-size: 20px;
  position: relative;
  margin-right: 7px;
}
.top-navbar.navbar .nav-item .message-btn i {
  position: relative;
  top: 3px;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu {
  width: 350px;
  max-width: 350px;
  border-radius: 5px 5px 0 0;
  padding: 0;
  left: 0;
  right: auto;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-header {
  border-radius: 5px 5px 0 0;
  background-color: #2962ff;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-header span {
  font-size: 13px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-header span.edit-btn {
  cursor: pointer;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-body {
  padding: 10px 5px;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-body .dropdown-item {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  text-align: center;
  padding: 8px 10px;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-body .dropdown-item span {
  margin-top: 8px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid #eeeeee;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-footer .dropdown-item {
  color: #2962ff !important;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar .left-nav {
  position: relative;
  top: 4px;
}
.top-navbar.navbar .left-nav .nav-item .nav-link {
  font-size: 22px;
}
.top-navbar.navbar .left-nav .nav-item .nav-link:hover {
  color: #e1000a;
}
.top-navbar.navbar .right-nav {
  margin-left: 20px;
}
.top-navbar.navbar .profile-nav-item .menu-profile .name {
  color: #2a2a2a;
  display: inline-block;
  position: relative;
  top: 2px;
  font-weight: 600;
}
.top-navbar.navbar .profile-nav-item .menu-profile img {
  width: 35px;
  height: 35px;
  display: inline-block;
  margin-right: 5;
  margin-left: 9px;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu {
  padding: 15px 15px 10px;
  min-width: 230px;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-header {
  padding: 0;
  border-bottom: 1px solid #f2f4f9;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-header .figure {
  position: relative;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-header .figure img {
  width: 80px;
  height: 80px;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-header .name {
  display: block;
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 17px;
  font-weight: 700;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-header .email {
  color: #686868;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-body ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-body .nav-item {
  margin-left: 0;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-body .nav-item .nav-link {
  color: #2a2a2a;
  padding: 5px 15px 5px 38px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-body .nav-item .nav-link i {
  color: #686868;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-body .nav-item .nav-link.active, .top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-body .nav-item .nav-link:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-body .nav-item .nav-link:hover i {
  transform: rotateY(-180deg);
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer {
  margin: 10px -15px 0;
  padding: 10px 15px 0;
  border-top: 1px solid #eeeeee;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer .nav-item .nav-link {
  color: red;
  padding: 5px 15px 5px 38px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer .nav-item .nav-link i {
  color: red;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer .nav-item .nav-link.active, .top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer .nav-item .nav-link:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer .nav-item .nav-link:hover i {
  transform: rotateY(-180deg);
}
.top-navbar.navbar.is-sticky {
  position: fixed;
  top: 0;
  left: 250px;
  z-index: 999;
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.07);
  background: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  width: calc(100% - 250px);
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.top-navbar.navbar.is-sticky.toggle-navbar-area {
  left: 55px;
  width: calc(100% - 55px);
}

@keyframes ring {
  0% {
    transform: rotateZ(0);
  }
  1% {
    transform: rotateZ(30deg);
  }
  3% {
    transform: rotateZ(-28deg);
  }
  5% {
    transform: rotateZ(34deg);
  }
  7% {
    transform: rotateZ(-32deg);
  }
  9% {
    transform: rotateZ(30deg);
  }
  11% {
    transform: rotateZ(-28deg);
  }
  13% {
    transform: rotateZ(26deg);
  }
  15% {
    transform: rotateZ(-24deg);
  }
  17% {
    transform: rotateZ(22deg);
  }
  19% {
    transform: rotateZ(-20deg);
  }
  21% {
    transform: rotateZ(18deg);
  }
  23% {
    transform: rotateZ(-16deg);
  }
  25% {
    transform: rotateZ(14deg);
  }
  27% {
    transform: rotateZ(-12deg);
  }
  29% {
    transform: rotateZ(10deg);
  }
  31% {
    transform: rotateZ(-8deg);
  }
  33% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  37% {
    transform: rotateZ(2deg);
  }
  39% {
    transform: rotateZ(-1deg);
  }
  41% {
    transform: rotateZ(1deg);
  }
  43% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(0);
  }
}
@keyframes pulse-secondary {
  0% {
    box-shadow: 0 0 0 rgba(255, 0, 10, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 0, 10, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 10, 0);
  }
}
@keyframes pulse-primary {
  0% {
    box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(41, 98, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(41, 98, 255, 0);
  }
}
.sidemenu-area {
  background-image: url("../../assets/img/02.jpg");
  background-size: cover;
}

/*================================================
Main Content CSS
=================================================*/
.main-content {
  transition: 0.5s;
  overflow: hidden;
  min-height: 100vh;
  padding-right: 30px;
  padding-left: 280px;
  padding-top: 70px;
}
.main-content.hide-sidemenu-area {
  padding-left: 85px;
}

.breadcrumb-area {
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 20px 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.breadcrumb-area h1 {
  margin-bottom: 0;
  position: relative;
  color: #2a2a2a;
  padding-right: 12px;
  font-size: 20px;
  font-weight: 700;
}
.breadcrumb-area h1::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 1px;
  background: #cecece;
}
.breadcrumb-area .breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding-left: 12px;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.breadcrumb-area .breadcrumb .item {
  color: #606060;
  margin-right: 25px;
  position: relative;
  font-size: 15px;
  font-family: "Nunito", sans-serif;
}
.breadcrumb-area .breadcrumb .item a {
  display: inline-block;
  color: #e1000a;
}
.breadcrumb-area .breadcrumb .item a:hover {
  color: #e1000a;
}
.breadcrumb-area .breadcrumb .item a i {
  font-size: 18px;
}
.breadcrumb-area .breadcrumb .item::before {
  content: "\e9fa";
  position: absolute;
  right: -21px;
  top: 2px;
  line-height: 18px;
  color: #606060;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
}
.breadcrumb-area .breadcrumb .item:last-child {
  margin-right: 0;
}
.breadcrumb-area .breadcrumb .item:last-child::before {
  display: none;
}

/*start-stats-box-css*/
.stats-card-box {
  margin-bottom: 30px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 25px 25px 25px 115px;
  border-radius: 5px;
  position: relative;
}
.stats-card-box .icon-box {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #e1000a;
  position: absolute;
  left: 25px;
  top: 47%;
  transform: translateY(-47%);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.15);
  text-align: center;
  font-size: 38px;
  color: #ffffff;
}
.stats-card-box .icon-box i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.stats-card-box .icon-box i.bx-paper-plane {
  top: 45%;
  transform: translateY(-45%);
  left: -3px;
}
.stats-card-box .sub-title {
  color: #a3a3a3;
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 400;
}
.stats-card-box h3 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 700;
}
.stats-card-box h3 .badge {
  line-height: initial;
  border-radius: 0;
  color: #13bb37;
  padding: 0;
  font-size: 13px;
  font-weight: 400;
}
.stats-card-box h3 .badge.badge-red {
  color: #ff4b00;
}
.stats-card-box .progress-list {
  margin-top: 8px;
}
.stats-card-box .progress-list .bar-inner {
  position: relative;
  width: 100%;
  border-bottom: 3px solid #d1d1d1;
}
.stats-card-box .progress-list .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 3px;
  background: #e1000a;
  transition: all 2000ms ease;
}
.stats-card-box .progress-list p {
  color: #a3a3a3;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 13px;
}

.col-lg-3:nth-child(2) .stats-card-box .icon-box {
  background-color: #13bb37;
  box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
}
.col-lg-3:nth-child(2) .stats-card-box .progress-list .bar-inner .bar {
  background: #13bb37;
}
.col-lg-3:nth-child(3) .stats-card-box .icon-box {
  background-color: #ff4b00;
  box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
}
.col-lg-3:nth-child(3) .stats-card-box .progress-list .bar-inner .bar {
  background: #ff4b00;
}
.col-lg-3:nth-child(4) .stats-card-box .icon-box {
  background-color: #4788ff;
  box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
}
.col-lg-3:nth-child(4) .stats-card-box .progress-list .bar-inner .bar {
  background: #4788ff;
}

.ecommerce-stats-area {
  margin: 5px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 30px 25px;
}
.ecommerce-stats-area .row {
  margin-left: 0;
  margin-right: 0;
}
.ecommerce-stats-area .row .col-lg-3 {
  padding-left: 0;
  padding-right: 0;
}
.ecommerce-stats-area .row .col-lg-3:nth-child(2) .single-stats-card-box .icon {
  background-color: #13bb37;
  box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
}
.ecommerce-stats-area .row .col-lg-3:nth-child(3) .single-stats-card-box .icon {
  background-color: #ff4b00;
  box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
}
.ecommerce-stats-area .row .col-lg-3:nth-child(4) .single-stats-card-box {
  border-right: none;
}
.ecommerce-stats-area .row .col-lg-3:nth-child(4) .single-stats-card-box .icon {
  background-color: #4788ff;
  box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
}

.single-stats-card-box {
  padding-left: 90px;
  position: relative;
}
.single-stats-card-box .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #e1000a;
  position: absolute;
  left: 0;
  top: 46%;
  transform: translateY(-46%);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.15);
  text-align: center;
  font-size: 38px;
  color: #ffffff;
}
.single-stats-card-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.single-stats-card-box .icon i.bx-paper-plane {
  top: 45%;
  transform: translateY(-45%);
  left: -3px;
}
.single-stats-card-box .sub-title {
  color: #a3a3a3;
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 400;
}
.single-stats-card-box h3 {
  margin-bottom: 0;
  color: #e1000a;
  position: relative;
  font-size: 30px;
  font-weight: 700;
}
.single-stats-card-box h3 .badge {
  line-height: initial;
  border-radius: 0;
  color: #13bb37;
  background-color: rgba(49, 203, 114, 0.18);
  padding: 2px 4px 1px;
  border-radius: 5px;
  position: relative;
  bottom: 3px;
  font-size: 11px;
  font-weight: 400;
}
.single-stats-card-box h3 .badge.badge-red {
  background-color: rgba(255, 91, 91, 0.18);
  color: #ff4b00;
}
.single-stats-card-box h3 .badge i {
  display: inline-block;
  margin-right: -2px;
}

/*end-stats-box-css*/
/*start-card-box-css*/
.card {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border: none;
  border-radius: 5px;
  padding: 25px;
}
.card .card-header {
  background-color: #ffffff;
  border-radius: 0;
  margin-bottom: 25px;
  padding: 0;
  border: none;
}
.card .card-header h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
}
.card .card-header .dropdown .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727E8C;
  font-size: 22px;
  line-height: 15px;
}
.card .card-header .dropdown .dropdown-toggle::after {
  display: none;
}
.card .card-header .dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: unset !important;
  left: auto !important;
  right: 0;
}
.card .card-header .dropdown .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}
.card .card-header .dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.card .card-header .dropdown .dropdown-menu .dropdown-item i {
  color: #686868;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.card .card-header .dropdown .dropdown-menu .dropdown-item.active, .card .card-header .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.card .card-header .dropdown .dropdown-menu .dropdown-item:hover i {
  transform: rotateY(-180deg);
}
.card .card-body {
  padding: 0;
}
.card .card-body .youtube-video iframe {
  width: 100%;
  border: none;
  height: 435px;
}
.card .card-body.border-primary {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-secondary {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-success {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-danger {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-warning {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-info {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-light {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-dark {
  border-width: 1px;
  border-style: solid;
}

.activity-card-box {
  margin-top: -20px;
}
.activity-card-box .progress-list {
  margin-top: 20px;
}
.activity-card-box .progress-list span {
  display: inline-block;
  color: #919191;
  font-size: 14px;
  font-weight: 400;
}
.activity-card-box .progress-list .bar-inner {
  margin-top: 8px;
  position: relative;
  width: 100%;
  border-radius: 0 5px 5px 0;
  border-bottom: 5px solid #F2F4F4;
}
.activity-card-box .progress-list .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 5px;
  background: #e1000a;
  border-radius: 0 5px 5px 0;
  transition: all 2000ms ease;
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}
.activity-card-box .row .col-lg-6:nth-child(2) .progress-list .bar-inner .bar {
  background-color: #33a73b;
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}
.activity-card-box .row .col-lg-6:nth-child(3) .progress-list .bar-inner .bar {
  background-color: #5f7be7;
  box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
}
.activity-card-box .row .col-lg-6:nth-child(4) .progress-list .bar-inner .bar {
  background-color: #FF5B5C;
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}

.activity-timeline-content ul {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 0;
  margin-top: -20px;
}
.activity-timeline-content ul li {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  margin-top: 20px;
  font-size: 15px;
  color: #2a2a2a;
  padding-left: 55px;
  padding-right: 10px;
}
.activity-timeline-content ul li::before {
  position: absolute;
  left: 25px;
  content: "";
  bottom: 0;
  width: 1px;
  height: 150%;
  background-color: #eeeeee;
}
.activity-timeline-content ul li i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #e1000a;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.activity-timeline-content ul li span {
  display: block;
  font-size: 14px;
  color: #a3a3a3;
  margin-bottom: 2px;
}
.activity-timeline-content ul li:nth-child(1)::before {
  height: 100%;
}
.activity-timeline-content ul li:nth-child(2)::before {
  height: 100%;
}
.activity-timeline-content ul li:nth-child(2) i {
  background-color: #13bb37;
}
.activity-timeline-content ul li:nth-child(3) i {
  background-color: #4788ff;
}
.activity-timeline-content ul li:nth-child(4) i {
  background-color: #ffc107;
}

.best-sales-box ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.best-sales-box ul li {
  color: #9d9d9d;
  font-size: 14px;
  margin-bottom: 4px;
}
.best-sales-box ul li:last-child {
  margin-bottom: 0;
}
.best-sales-box ul li span {
  display: inline-block;
  min-width: 20px;
  margin-right: 3px;
}
.best-sales-box ul li .bar-inner {
  position: relative;
  width: 100%;
  border-radius: 0 5px 5px 0;
  border-bottom: 5px solid #F2F4F4;
}
.best-sales-box ul li .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 5px;
  background: #e1000a;
  border-radius: 0 5px 5px 0;
  transition: all 2000ms ease;
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}

.browser-used-box {
  margin-left: -25px;
  margin-right: -25px;
}
.browser-used-box table {
  margin-bottom: 0;
}
.browser-used-box table thead th {
  vertical-align: middle;
  white-space: nowrap;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.browser-used-box table thead th:first-child {
  padding-left: 25px;
}
.browser-used-box table thead th:last-child {
  padding-right: 25px;
}
.browser-used-box table tbody td {
  vertical-align: bottom;
  border: none;
  white-space: nowrap;
  color: #2a2a2a;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 0;
}
.browser-used-box table tbody td:first-child {
  padding-left: 25px;
}
.browser-used-box table tbody td:last-child {
  padding-right: 25px;
}
.browser-used-box table tbody td i {
  color: #13bb37;
  display: inline-block;
  margin-right: -2px;
}
.browser-used-box table tbody td i.red {
  color: #ff4b00;
}

.browser-statistics-box .box {
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 15px;
}
.browser-statistics-box .box:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.browser-statistics-box .box:first-child {
  margin-top: 7px;
}
.browser-statistics-box .box .img {
  background-color: #f3f3f3;
  border-radius: 50%;
  text-align: center;
  width: 45px;
  height: 40px;
  line-height: 37px;
}
.browser-statistics-box .box .content {
  margin-left: 15px;
  width: 100%;
}
.browser-statistics-box .box .content h5 {
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 600;
}
.browser-statistics-box .box .content .bar-inner {
  position: relative;
  width: 100%;
  border-bottom: 3px solid #d1d1d1;
  border-radius: 5px;
}
.browser-statistics-box .box .content .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 3px;
  background: #e1000a;
  transition: all 2000ms ease;
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  border-radius: 5px;
}
.browser-statistics-box .box .content .count-box {
  margin-left: 15px;
  color: #2a2a2a;
}
.browser-statistics-box .box:nth-child(2) .content .bar-inner .bar {
  background-color: #33a73b;
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}
.browser-statistics-box .box:nth-child(3) .content .bar-inner .bar {
  background-color: #5f7be7;
  box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
}
.browser-statistics-box .box:nth-child(4) .content .bar-inner .bar {
  background-color: #FF5B5C;
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}
.browser-statistics-box .box:nth-child(5) .content .bar-inner .bar {
  background-color: #33a73b;
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}
.browser-statistics-box .box:nth-child(7) .content .bar-inner .bar {
  background-color: #5f7be7;
  box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
}
.browser-statistics-box .box:nth-child(8) .content .bar-inner .bar {
  background-color: #FF5B5C;
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}

.new-product-box {
  border-radius: 5px;
  margin-bottom: 25px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 21.5px 15px;
  text-align: center;
  color: #828D99;
  font-size: 16px;
  font-weight: 400;
}
.new-product-box .icon {
  width: 45px;
  height: 45px;
  background-color: #fbd8d7;
  color: #e1000a;
  border-radius: 50%;
  position: relative;
  transition: 0.5s;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.new-product-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.new-product-box .sub-text {
  color: #2a2a2a;
  margin-top: 3px;
  font-size: 17px;
}
.new-product-box:hover .icon {
  background-color: #e1000a;
  color: #ffffff;
}

.new-user-box {
  margin-bottom: 25px;
  border-radius: 5px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 21.5px 15px;
  text-align: center;
  color: #828D99;
  font-size: 16px;
  font-weight: 400;
}
.new-user-box .icon {
  width: 45px;
  height: 45px;
  background-color: #d0f1d7;
  color: #13bb37;
  border-radius: 50%;
  position: relative;
  transition: 0.5s;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.new-user-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.new-user-box .sub-text {
  color: #2a2a2a;
  margin-top: 3px;
  font-size: 17px;
}
.new-user-box:hover .icon {
  background-color: #13bb37;
  color: #ffffff;
}

.upcoming-product-box {
  border-radius: 5px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 21.5px 15px;
  text-align: center;
  color: #828D99;
  font-size: 16px;
  font-weight: 400;
}
.upcoming-product-box .icon {
  width: 45px;
  height: 45px;
  background-color: #dbe5f7;
  color: #4788ff;
  border-radius: 50%;
  position: relative;
  transition: 0.5s;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.upcoming-product-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.upcoming-product-box .sub-text {
  color: #2a2a2a;
  margin-top: 3px;
  font-size: 17px;
}
.upcoming-product-box:hover .icon {
  background-color: #4788ff;
  color: #ffffff;
}

.widget-todo-list {
  border-top: 1px solid #eeeeee;
  padding-top: 18px !important;
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: -10px;
  margin-top: -10px;
}
.widget-todo-list ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-todo-list ul li {
  position: relative;
  transition: 0.5s;
  padding-left: 60px;
  padding-right: 110px;
  padding-top: 9px;
  padding-bottom: 10px;
}
.widget-todo-list ul li .checkbox {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}
.widget-todo-list ul li .todo-item-title {
  position: relative;
  padding-left: 53px;
}
.widget-todo-list ul li .todo-item-title img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.widget-todo-list ul li .todo-item-title h3 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
}
.widget-todo-list ul li .todo-item-title p {
  color: #919191;
  font-size: 15px;
  margin-bottom: 0;
}
.widget-todo-list ul li .todo-item-action {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
}
.widget-todo-list ul li .todo-item-action a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 3px;
  background-color: #c6e8cd;
  color: #13bb37;
  text-align: center;
  margin-left: 3px;
}
.widget-todo-list ul li .todo-item-action a:hover {
  background-color: #13bb37;
  color: #ffffff;
}
.widget-todo-list ul li .todo-item-action a.delete {
  background-color: #f9d0d9 !important;
  color: #e1000a !important;
}
.widget-todo-list ul li .todo-item-action a.delete:hover {
  background-color: #e1000a !important;
  color: #ffffff !important;
}
.widget-todo-list ul li:hover {
  background-color: #F2F4F4;
}
.widget-todo-list ul li:nth-child(2) .todo-item-action a {
  background-color: #d1def5;
  color: #4788ff;
}
.widget-todo-list ul li:nth-child(2) .todo-item-action a:hover {
  background-color: #4788ff;
  color: #ffffff;
}
.widget-todo-list ul li:nth-child(3) .todo-item-action a {
  background-color: #f5d1c4;
  color: #fb4a06;
}
.widget-todo-list ul li:nth-child(3) .todo-item-action a:hover {
  background-color: #fb4a06;
  color: #ffffff;
}
.widget-todo-list ul li:nth-child(4) .todo-item-action a {
  background-color: #d1def5;
  color: #4788ff;
}
.widget-todo-list ul li:nth-child(4) .todo-item-action a:hover {
  background-color: #4788ff;
  color: #ffffff;
}

.cbx {
  margin: auto;
  user-select: none;
  cursor: pointer;
}
.cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #DFE3E7;
  transition: all 0.2s ease;
}
.cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #e1000a;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.cbx span:first-child:hover span:first-child {
  border-color: #e1000a;
}
.inp-cbx:checked + .cbx span:first-child {
  background: #e1000a;
  border-color: #e1000a;
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}
.inp-cbx + .cbx.active span:first-child {
  background: #e1000a;
  border-color: #e1000a;
  animation: wave 0.4s ease;
}
.inp-cbx + .cbx.active span:first-child svg {
  stroke-dashoffset: 0;
}
.inp-cbx + .cbx.active span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
.recent-orders-box table {
  margin-bottom: 0;
}
.recent-orders-box table thead th {
  text-align: center;
  white-space: nowrap;
  border-bottom: 2px solid #f6f6f7;
  border-top: none;
  font-size: 15px;
}
.recent-orders-box table thead th:first-child {
  text-align: left;
  padding-left: 0px;
}
.recent-orders-box table thead th:last-child {
  text-align: right;
  padding-right: 0px;
}
.recent-orders-box table tbody tr {
  transition: 0.5s;
}
.recent-orders-box table tbody tr td {
  text-align: center;
  padding: 15px;
  vertical-align: middle;
  white-space: nowrap;
  border-bottom: 1px solid #f6f6f7;
  border-top: none;
  font-size: 14.4px;
}
.recent-orders-box table tbody tr td:first-child {
  text-align: left;
  padding-left: 0;
}
.recent-orders-box table tbody tr td .badge {
  padding: 0;
  background-color: transparent !important;
  color: #2a2a2a;
  font-weight: 600;
  font-size: 14.4px;
}
.recent-orders-box table tbody tr td .badge.badge-primary {
  color: #13bb37;
}
.recent-orders-box table tbody tr td .badge.badge-success {
  color: #f59d03;
}
.recent-orders-box table tbody tr td .badge.badge-danger {
  color: #ff5c75;
}
.recent-orders-box table tbody tr td img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}
.recent-orders-box table tbody tr td.name {
  font-weight: 700;
}
.recent-orders-box table tbody tr:last-child td {
  padding-bottom: 10px;
  border-bottom: none;
}

.new-customer-box table {
  margin-bottom: 0;
}
.new-customer-box table thead th {
  text-align: center;
  border-bottom: 2px solid #f6f6f7;
  border-top: none;
  padding: 0 15px 14px;
  font-size: 15px;
}
.new-customer-box table thead th:first-child {
  text-align: left;
  padding-left: 0;
}
.new-customer-box table tbody tr {
  transition: 0.5s;
}
.new-customer-box table tbody tr td {
  text-align: center;
  padding: 13px 15px;
  vertical-align: middle;
  border-bottom: 1px solid #f6f6f7;
  border-top: none;
  white-space: nowrap;
  font-size: 14.4px;
}
.new-customer-box table tbody tr td:first-child {
  text-align: left;
  padding-left: 0;
}
.new-customer-box table tbody tr td:last-child {
  text-align: right;
  padding-right: 0;
}
.new-customer-box table tbody tr td img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}
.new-customer-box table tbody tr td .text-success {
  font-size: 16px;
}
.new-customer-box table tbody tr td .text-danger {
  font-size: 16px;
}
.new-customer-box table tbody tr td .text-warning {
  font-size: 17px;
}
.new-customer-box table tbody tr td.name {
  font-weight: 700;
}
.new-customer-box table tbody tr:last-child td {
  padding-bottom: 0;
  border-bottom: none;
}

.revenue-summary-content p {
  font-size: 14px;
}
.revenue-summary-content h5 {
  font-size: 20px;
}
.revenue-summary-content h5 .primary-text {
  color: #13bb37;
  display: inline-block;
  margin-left: 1px;
  position: relative;
  bottom: 1px;
  font-size: 12px;
}
.revenue-summary-content h5 .danger-text {
  color: #ff4b00;
  display: inline-block;
  margin-left: 1px;
  position: relative;
  bottom: 1px;
  font-size: 12px;
}

.greetings-card-box .image {
  margin-right: 20px;
  margin-top: 60px;
}
.greetings-card-box .content {
  margin-left: 10px;
  text-align: center;
}
.greetings-card-box .content .icon {
  text-align: center;
  width: 65px;
  height: 65px;
  position: relative;
  border-radius: 50%;
  background-color: #fcdedf;
  color: #e1000a;
  font-size: 35px;
  transition: 0.5s;
  margin-left: auto;
  margin-right: auto;
}
.greetings-card-box .content .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.greetings-card-box .content h3 {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 700;
}
.greetings-card-box .content p {
  color: #727E8C;
}
.greetings-card-box:hover .content .icon {
  background-color: #e1000a;
  color: #fcdedf;
}

.social-marketing-box .list {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 12.5px;
  padding-bottom: 12.5px;
  padding-left: 50px;
}
.social-marketing-box .list:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.social-marketing-box .list .icon {
  width: 38px;
  height: 40px;
  position: absolute;
  border-radius: 3px;
  left: 0;
  top: 1px;
  font-size: 23px;
  text-align: center;
  color: #ffffff;
}
.social-marketing-box .list .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  transform: translateY(-45%);
}
.social-marketing-box .list .icon.facebook {
  background-color: #4267b2;
}
.social-marketing-box .list .icon.twitter {
  background-color: #1da1f2;
}
.social-marketing-box .list .icon.instagram {
  background-color: #A1755C;
}
.social-marketing-box .list .icon.linkedin {
  background-color: #0077B5;
}
.social-marketing-box .list .icon.youtube {
  background-color: #ff0000;
}
.social-marketing-box .list .icon.github {
  background-color: #2962ff;
}
.social-marketing-box .list h4 {
  font-size: 15px;
  font-weight: 600;
}
.social-marketing-box .list h4 a {
  color: #2a2a2a;
  text-decoration: none;
}
.social-marketing-box .list h4 a:hover {
  color: #e1000a;
}
.social-marketing-box .list p {
  color: #828D99;
}
.social-marketing-box .list .stats {
  position: absolute;
  right: 0;
  top: 14px;
  font-size: 14px;
}

.top-rated-product-box ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.top-rated-product-box ul .single-product {
  position: relative;
  border-bottom: 1px solid #f6f6f7;
  margin-bottom: 15px;
  padding-left: 70px;
  padding-bottom: 15px;
  padding-right: 45px;
}
.top-rated-product-box ul .single-product .image {
  position: absolute;
  left: 0;
  top: 0;
}
.top-rated-product-box ul .single-product .image img {
  border: 1px solid #f3f3f3;
  width: 55px;
  height: 55px;
}
.top-rated-product-box ul .single-product h4 {
  font-size: 15px;
  font-weight: 700;
}
.top-rated-product-box ul .single-product h4 a {
  color: #2a2a2a;
  text-decoration: none;
}
.top-rated-product-box ul .single-product h4 a:hover {
  color: #e1000a;
}
.top-rated-product-box ul .single-product p {
  color: #686c71;
}
.top-rated-product-box ul .single-product .price {
  color: #e1000a;
}
.top-rated-product-box ul .single-product .rating i {
  color: #ffce00;
  font-size: 14px;
}
.top-rated-product-box ul .single-product .view-link {
  position: absolute;
  top: 20px;
  right: 0;
  border: 1px solid #e1000a;
  color: #e1000a;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 31px;
  border-radius: 50%;
  font-size: 15px;
}
.top-rated-product-box ul .single-product .view-link:hover {
  background-color: #e1000a;
  color: #ffffff;
}
.top-rated-product-box ul .single-product:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert .alert-link:hover {
  text-decoration: underline;
}

.demo-code-preview {
  border: 1px solid #ebebeb;
  padding: 20px 25px 15px;
  margin-top: 2rem;
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
}
.demo-code-preview::before {
  content: attr(data-label);
  display: block;
  position: absolute;
  top: -9px;
  left: 20px;
  letter-spacing: 1px;
  background-color: #ffffff;
  font-size: 11px;
  padding: 0 5px;
  color: #e1000a;
}
.demo-code-preview .highlight .btn.btn-clipboard {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 0;
  box-shadow: unset !important;
}
.demo-code-preview .highlight pre {
  margin-bottom: 0;
  font-family: "Nunito", sans-serif;
}
.demo-code-preview .highlight pre .c {
  color: #999;
}
.demo-code-preview .highlight pre .nt {
  color: #905;
}
.demo-code-preview .highlight pre .na {
  color: #690;
}
.demo-code-preview .highlight pre .s {
  color: #07a;
}

.badge-card-box .card-header {
  margin-bottom: 17px;
}

.button-card-box .card-header {
  margin-bottom: 17px;
}

.button-group-card-box .card-header {
  margin-bottom: 17px;
}

.dropdowns-card-box .card-header {
  margin-bottom: 17px;
}

.popovers-card-box .card-header {
  margin-bottom: 17px;
}

.tooltips-card-box .card-header {
  margin-bottom: 17px;
}

.spinners-card-box .card-header {
  margin-bottom: 17px;
}

.collapse-card-box .card-header {
  margin-bottom: 17px;
}

.accordion-box .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.accordion-box .accordion .accordion-item {
  display: block;
  background: #f5f5ff;
  margin-bottom: 10px;
}
.accordion-box .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-box .accordion .accordion-title {
  padding: 12px 40px 10px 20px;
  color: #2a2a2a;
  position: relative;
  text-decoration: none;
  display: block;
  font-size: 15px;
  font-weight: 600;
}
.accordion-box .accordion .accordion-title i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}
.accordion-box .accordion .accordion-title.active {
  background-color: #e1000a;
  color: #ffffff;
}
.accordion-box .accordion .accordion-title.active i::before {
  content: "\eaf8";
}
.accordion-box .accordion .accordion-title:hover {
  background-color: #e1000a;
  color: #ffffff;
}
.accordion-box .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px;
  font-size: 15px;
  color: #1f1b1b;
  line-height: 1.8;
  background-color: #fafafa;
}
.accordion-box .accordion .accordion-content.show {
  display: block;
}

.icon-box-inner {
  background-color: #ffffff;
}
.icon-box-inner .icon-img {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.icon-box-inner .icon-box-name {
  padding-top: 10px;
  padding-bottom: 20px;
  font-weight: 700;
}

.feather-icons-list .icon-box {
  margin-bottom: 30px;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  outline: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.feather-icons-list .icon-box .icon-box-inner {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px 25px;
  outline: 0;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.feather-icons-list .icon-box .icon-box-inner .icon-base {
  display: inline-block;
  margin-right: 5px;
  color: #2a2a2a;
  transition: 0.5s;
}
.feather-icons-list .icon-box .icon-box-inner .icon-box-name {
  color: #6084a4;
  display: inline-block;
  position: relative;
  top: 1.5px;
}
.feather-icons-list .icon-box .icon-box-inner:hover .icon-base {
  transform: scale(1.2);
}

.grid-bg-example .row {
  margin-left: 0;
  margin-right: 0;
}
.grid-bg-example .row + .row {
  margin-top: 1rem;
}
.grid-bg-example .row > .col, .grid-bg-example .row > [class^=col-] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
  margin-top: 1rem;
}
.bd-example-row-flex-cols .row:first-child {
  margin-top: 0;
}

.bd-example-border-utils [class^=border] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  background-color: #f5f5f5;
}
.bd-example-border-utils.bd-example-border-utils-0 [class^=border] {
  border: 1px solid #dee2e6;
}

#map {
  height: 500px;
  width: 100%;
}

/*end-card-box-css*/
/*start-welcome-area*/
.welcome-area {
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 25px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.welcome-content h1 {
  font-size: 40px;
  font-weight: 700;
}
.welcome-content p {
  color: #646262;
  font-size: 18px;
}

.welcome-img {
  text-align: center;
  padding-top: 20px;
}
.welcome-img img {
  width: 100%;
}

/*end-welcome-area*/
/*start-all-chart-style-css*/
#website-analytics-chart.extra-margin {
  margin: -20px 0 -15px -20px;
}
#website-analytics-chart .apexcharts-toolbar {
  display: none;
}
#website-analytics-chart .apexcharts-legend {
  bottom: 0 !important;
}

#emailSend-chart.extra-margin {
  margin: -15px 0 -15px 0;
}

#activity-timeline-chart .apexcharts-legend-series {
  margin-bottom: 6px !important;
}
#activity-timeline-chart .apexcharts-legend-series:last-child {
  margin-bottom: 0 !important;
}
#activity-timeline-chart .apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 5px;
}

#traffic-source-chart.extra-margin {
  margin: -20px 0 -25px -20px;
}

#revenue-growth-chart.extra-margin {
  margin: -20px 0 -25px -20px;
}
#revenue-growth-chart.extra-margin .apexcharts-toolbar {
  display: none;
}

#revenue-summary-chart.extra-margin {
  margin: 0 0 -15px -20px;
}
#revenue-summary-chart.extra-margin .apexcharts-toolbar {
  display: none;
}
#revenue-summary-chart .apexcharts-legend {
  bottom: 0 !important;
}

#client-recollection-chart.extra-margin {
  margin: -30px 0 -15px -20px;
}
#client-recollection-chart .apexcharts-toolbar {
  display: none;
}
#client-recollection-chart .apexcharts-legend {
  bottom: 0 !important;
}

#apex-chart-datetime-x-axis .toolbar {
  margin-left: 20px;
}
#apex-chart-datetime-x-axis button {
  background: #ffffff;
  color: #222222;
  border: 1px solid #e7e7e7;
  border-bottom: 2px solid #dddddd;
  border-radius: 2px;
  padding: 4px 17px;
}
#apex-chart-datetime-x-axis button.active {
  color: #ffffff;
  background: #008FFB;
  border: 1px solid blue;
  border-bottom: 2px solid blue;
}

.chartjs-colors .bg-purple {
  background-color: #c679e3 !important;
}
.chartjs-colors .bg-secondary {
  background-color: #f48665 !important;
}
.chartjs-colors .bg-pink {
  background-color: #FF1494 !important;
}
.chartjs-colors .bg-success-light {
  background-color: rgba(80, 196, 85, 0.3) !important;
}
.chartjs-colors .bg-primary-light {
  background-color: rgba(63, 81, 181, 0.3) !important;
}

/*end-all-chart-style-css*/
/*start-all-maps-style-css*/
#world-map-markers {
  height: 398px;
}

/*end-all-maps-style-css*/
/*================================================
App Email CSS
=================================================*/
.email-content-area {
  margin-bottom: 30px;
}
.email-content-area .sidebar-left {
  float: left;
}
.email-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
  height: calc(100vh - 201px);
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn {
  border: none;
  padding: 12px 20px 11px;
  position: relative;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn i {
  font-size: 20px;
  position: absolute;
  margin-left: -23px;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn-primary {
  color: #ffffff;
  background-color: #e1000a;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn-primary:not(:disabled):not(.disabled).active, .email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn-primary:not(:disabled):not(.disabled):active, .show > .email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn-primary.dropdown-toggle {
  background-color: #e1000a;
  color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn-primary:not(:disabled):not(.disabled).active:focus, .email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn-primary.dropdown-toggle:focus {
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn-primary:hover, .email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn-primary:focus {
  background-color: #e1000a;
  color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-messages .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  margin-bottom: 16px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-messages .list-group-item i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-messages .list-group-item:last-child {
  margin-bottom: 0;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-messages .list-group-item.active {
  background-color: #ffffff;
  color: #e1000a;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-messages .list-group-item .badge {
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px 4px 6px;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-messages .list-group-item:hover {
  color: #e1000a;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-messages .list-group-item:hover i {
  transform: rotateY(-180deg);
  color: #e1000a;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-label {
  letter-spacing: 1px;
  color: #828D99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-labels .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-labels .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #e1000a;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-labels .list-group-item:last-child {
  margin-bottom: 0;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-labels .list-group-item.active {
  background-color: #ffffff;
  color: #e1000a;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-labels .list-group-item:hover {
  color: #e1000a;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-labels .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-labels .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-labels .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .list-group-labels .list-group-item:nth-child(5)::before {
  border-color: #707070;
}
.email-content-area .favorite {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  color: #C7CFD6;
  margin-top: 8px;
  margin-bottom: -5px;
}
.email-content-area .favorite.active {
  color: #FDAC41;
}
.email-content-area .favorite.active i::before {
  content: "\ed99";
}
.email-content-area .content-right .email-list-wrapper .email-list {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header {
  padding: 0px 20px;
  border-bottom: 1px solid #e8ebf1;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left {
  position: relative;
  top: 3px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .checkbox {
  position: relative;
  top: -2px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .btn-icon {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .btn-icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 47%;
  transform: translateY(-47%);
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .btn-icon:hover {
  color: #ffffff;
  background-color: #e1000a;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .btn-icon::after {
  display: none;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: unset !important;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .dropdown .dropdown-menu.show {
  margin-top: 45px;
  opacity: 1;
  visibility: visible;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .dropdown .dropdown-menu .dropdown-item i {
  color: #686868;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .dropdown .dropdown-menu .dropdown-item.active, .email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-left .dropdown .dropdown-menu .dropdown-item:hover i {
  transform: rotateY(-180deg);
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-right {
  position: relative;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-right label {
  position: absolute;
  left: 15px;
  color: #71738d;
  margin-bottom: 0;
  top: 14px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-right .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 2px 15px 0 39px;
  transition: 0.5s;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-right .form-control:focus {
  box-shadow: unset;
  background-color: #eef5f9;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-right .form-control:focus::placeholder {
  color: transparent;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-header .header-right .form-control::placeholder {
  color: #a8a8a8;
  transition: 0.5s;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper {
  height: calc(100vh - 343px);
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8ebf1;
  padding: 5px 10px;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  font-size: 15px;
  transition: 0.5s;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item:last-child {
  border-bottom: none;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-actions .checkbox {
  position: relative;
  top: 2px;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-actions .favorite {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  color: #C7CFD6;
  margin-top: 8px;
  margin-bottom: -5px;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-actions .favorite.active {
  color: #FDAC41;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-actions .favorite.active i::before {
  content: "\ed99";
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-detail {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-detail div {
  position: relative;
  padding-left: 65px;
  margin-top: -2px;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-detail img {
  position: relative;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-detail .from {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-detail .msg {
  color: #71738d;
  font-size: 15px;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item .email-list-detail .date {
  color: #2a2a2a;
  font-weight: 600;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item.read {
  background-color: #f7f7f7;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item.read .email-list-detail .from {
  color: #616161;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item.read .email-list-detail .msg {
  color: #71738d;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item.read .email-list-detail .date {
  color: #616161;
}
.email-content-area .content-right .email-list-wrapper .email-list .list-wrapper .email-list-item:hover {
  box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
  transition: 0.5s;
  z-index: 1;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-footer {
  border-top: 1px solid #e8ebf1;
  padding: 15px 20px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-footer p {
  color: #71738d;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-footer .email-pagination-btn {
  margin-bottom: -5px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-footer .email-pagination-btn button {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 5px;
  margin-left: 5px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-footer .email-pagination-btn button i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-footer .email-pagination-btn button:hover {
  color: #ffffff;
  background-color: #e1000a;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-footer .email-pagination-btn button.email-pagination-prev {
  border-radius: 5px 0 0 5px;
}
.email-content-area .content-right .email-list-wrapper .email-list .email-list-footer .email-pagination-btn button.email-pagination-next {
  border-radius: 0 5px 5px 0;
}

/*================================================
App Email Read CSS
=================================================*/
.email-read-content-area {
  margin-bottom: 30px;
}
.email-read-content-area .sidebar-left {
  float: left;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
  height: calc(100vh - 201px);
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn {
  border: none;
  padding: 12px 20px 11px;
  position: relative;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn i {
  font-size: 20px;
  position: absolute;
  margin-left: -23px;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn-primary {
  color: #ffffff;
  background-color: #e1000a;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn-primary:not(:disabled):not(.disabled).active, .email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn-primary:not(:disabled):not(.disabled):active, .show > .email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn-primary.dropdown-toggle {
  background-color: #e1000a;
  color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn-primary:not(:disabled):not(.disabled).active:focus, .email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn-primary.dropdown-toggle:focus {
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn-primary:hover, .email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .btn-primary:focus {
  background-color: #e1000a;
  color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-messages .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  margin-bottom: 16px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-messages .list-group-item i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-messages .list-group-item:last-child {
  margin-bottom: 0;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-messages .list-group-item.active {
  background-color: #ffffff;
  color: #e1000a;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-messages .list-group-item .badge {
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px 4px 6px;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-messages .list-group-item:hover {
  color: #e1000a;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-messages .list-group-item:hover i {
  transform: rotateY(-180deg);
  color: #e1000a;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-label {
  letter-spacing: 1px;
  color: #828D99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-labels .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-labels .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #e1000a;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-labels .list-group-item:last-child {
  margin-bottom: 0;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-labels .list-group-item.active {
  background-color: #ffffff;
  color: #e1000a;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-labels .list-group-item:hover {
  color: #e1000a;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-labels .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-labels .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-labels .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content .email-read-menu .list-group-labels .list-group-item:nth-child(5)::before {
  border-color: #707070;
}
.email-read-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header {
  padding: 15px 20px;
  border-bottom: 1px solid #e8ebf1;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-left {
  position: relative;
  top: 1px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-left .go-back {
  color: #727E8C;
  font-size: 25px;
  line-height: 0;
  position: relative;
  top: -1px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-left .go-back:hover {
  color: #e1000a;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-left h3 {
  font-size: 18px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-left h3 .badge {
  color: #ffffff;
  border-radius: 30px;
  padding: 5px 15px;
  font-weight: 500;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right {
  position: relative;
  top: 3px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .btn-icon {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .btn-icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 47%;
  transform: translateY(-47%);
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .btn-icon:hover {
  color: #ffffff;
  background-color: #e1000a;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .btn-icon::after {
  display: none;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: unset !important;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .dropdown .dropdown-menu.show {
  margin-top: 45px;
  opacity: 1;
  visibility: visible;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .dropdown .dropdown-menu .dropdown-item i {
  color: #686868;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .dropdown .dropdown-menu .dropdown-item.active, .email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-list-header .header-right .dropdown .dropdown-menu .dropdown-item:hover i {
  transform: rotateY(-180deg);
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-read-scroll-area {
  height: calc(100vh - 338px);
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper {
  padding: 20px;
  margin-bottom: 0;
  list-style-type: none;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item {
  border-radius: 5px;
  border: 1px solid #ffffff;
  padding: 15px 20px;
  width: 100%;
  position: relative;
  font-size: 15px;
  transition: 0.5s;
  margin-bottom: 15px;
  box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  background-color: #ffffff;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item:last-child {
  margin-bottom: 0;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .user-information {
  position: relative;
  padding-left: 65px;
  margin-top: -2px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .name {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .name .email {
  color: #727E8C;
  font-weight: normal;
  font-size: 15px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .to {
  color: #71738d;
  font-size: 13px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .date {
  color: #727E8C;
  font-weight: 500;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .favorite {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  color: #C7CFD6;
  margin-left: 12px;
  margin-right: 8px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .favorite.active {
  color: #FDAC41;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .favorite.active i::before {
  content: "\ed99";
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .dropdown .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727E8C;
  font-size: 22px;
  line-height: 15px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .dropdown .dropdown-toggle::after {
  display: none;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: unset !important;
  left: auto !important;
  right: 0;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .dropdown .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .dropdown .dropdown-menu .dropdown-item i {
  color: #686868;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .dropdown .dropdown-menu .dropdown-item.active, .email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-detail .email-information .dropdown .dropdown-menu .dropdown-item:hover i {
  transform: rotateY(-180deg);
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-content {
  margin-top: 25px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-content p {
  margin-bottom: 10px;
  color: #727E8C;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-content .email-read-list-footer {
  border-top: 1px solid #e8ebf1;
  padding-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-top: 20px;
  margin-right: -20px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-content .email-read-list-footer label {
  font-size: 14px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-content .email-read-list-footer ul li {
  margin-bottom: 5px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-read-list-item .email-read-list-content .email-read-list-footer ul li a {
  color: #727E8C;
  text-decoration: none;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-reply-list-item {
  margin-top: 30px;
  margin-bottom: 8px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-reply-list-item a {
  box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 10px 30px;
  border-radius: 30px;
  margin-right: 10px;
  text-decoration: none;
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 700;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-reply-list-item a:last-child {
  margin-right: 0;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-reply-list-item a i {
  font-size: 20px;
  position: relative;
  top: 3px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .list-wrapper .email-reply-list-item a:hover {
  color: #ffffff;
  border-color: #e1000a;
  background-color: #e1000a;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-list-footer {
  border-top: 1px solid #e8ebf1;
  padding: 15px 20px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-list-footer p {
  color: #71738d;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-list-footer .email-pagination-btn {
  margin-bottom: -5px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-list-footer .email-pagination-btn button {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 5px;
  margin-left: 5px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-list-footer .email-pagination-btn button i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-list-footer .email-pagination-btn button:hover {
  color: #ffffff;
  background-color: #e1000a;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-list-footer .email-pagination-btn button.email-pagination-prev {
  border-radius: 5px 0 0 5px;
}
.email-read-content-area .content-right .email-read-list-wrapper .email-read-list .email-list-footer .email-pagination-btn button.email-pagination-next {
  border-radius: 0 5px 5px 0;
}

/*================================================
App Email Compose CSS
=================================================*/
.email-compose-content-area {
  margin-bottom: 30px;
}
.email-compose-content-area .sidebar-left {
  float: left;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 8px;
  width: 260px;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-messages .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  margin-bottom: 5px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-messages .list-group-item i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-messages .list-group-item:last-child {
  margin-bottom: 0;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-messages .list-group-item.active {
  background-color: #ffffff;
  color: #e1000a;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-messages .list-group-item .badge {
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px 4px 6px;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-messages .list-group-item:hover {
  color: #e1000a;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-messages .list-group-item:hover i {
  transform: rotateY(-180deg);
  color: #e1000a;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-label {
  letter-spacing: 1px;
  color: #828D99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-labels .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-labels .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #e1000a;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-labels .list-group-item:last-child {
  margin-bottom: 0;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-labels .list-group-item.active {
  background-color: #ffffff;
  color: #e1000a;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-labels .list-group-item:hover {
  color: #e1000a;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-labels .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-labels .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-labels .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content .email-compose-menu .list-group-labels .list-group-item:nth-child(5)::before {
  border-color: #707070;
}
.email-compose-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}
.email-compose-content-area .content-right .email-compose-list-wrapper {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border: none;
  border-radius: 5px;
  padding: 25px;
}
.email-compose-content-area .content-right .email-compose-list-wrapper h3 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .card {
  background-color: transparent;
  box-shadow: unset;
  border-radius: 5px;
  padding: 0;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .card .card-header {
  background-color: transparent;
  margin-bottom: 0;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .form-control {
  height: 42px;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .form-control:focus {
  border-color: #b1bbc4;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .note-editor.note-airframe, .email-compose-content-area .content-right .email-compose-list-wrapper form .note-editor.note-frame {
  border-color: #ced4da;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .note-popover .popover-content > .note-btn-group, .email-compose-content-area .content-right .email-compose-list-wrapper form .note-toolbar > .note-btn-group {
  margin-top: -1px;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .form-group .btn {
  transition: 0.5s;
  border-radius: 2px;
  box-shadow: unset !important;
  padding: 10px 25px 9.5px;
  font-size: 15px;
  font-weight: 700;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .form-group .btn i {
  position: relative;
  top: 1px;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .form-group .btn.btn-success {
  background-color: #3578e5;
  color: #ffffff;
  border-color: #3578e5;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .form-group .btn.btn-success:hover, .email-compose-content-area .content-right .email-compose-list-wrapper form .form-group .btn.btn-success:focus {
  background-color: #3578e5;
  color: #ffffff;
  border-color: #3578e5;
  box-shadow: 1px 5px 24px 0 rgba(102, 88, 221, 0.25) !important;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .form-group .btn.btn-primary {
  background-color: #e1000a;
  color: #ffffff;
  border-color: #e1000a;
}
.email-compose-content-area .content-right .email-compose-list-wrapper form .form-group .btn.btn-primary:hover, .email-compose-content-area .content-right .email-compose-list-wrapper form .form-group .btn.btn-primary:focus {
  background-color: #e33e45;
  color: #ffffff;
  border-color: #e33e45;
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.25) !important;
}

/*================================================
App Todo CSS
=================================================*/
.todo-content-area {
  margin-bottom: 30px;
}
.todo-content-area .sidebar-left {
  float: left;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
  height: calc(100vh - 201px);
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn {
  border: none;
  padding: 12px 20px 11px;
  position: relative;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn i {
  font-size: 20px;
  position: absolute;
  margin-left: -23px;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn-primary {
  color: #ffffff;
  background-color: #e1000a;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn-primary:not(:disabled):not(.disabled).active, .todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn-primary:not(:disabled):not(.disabled):active, .show > .todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn-primary.dropdown-toggle {
  background-color: #e1000a;
  color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn-primary:not(:disabled):not(.disabled).active:focus, .todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn-primary.dropdown-toggle:focus {
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn-primary:hover, .todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn-primary:focus {
  background-color: #e1000a;
  color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-messages .list-group-item {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  text-decoration: none;
  margin-bottom: 16px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-messages .list-group-item i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-messages .list-group-item:last-child {
  margin-bottom: 0;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-messages .list-group-item.active {
  background-color: #ffffff;
  color: #e1000a;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-messages .list-group-item .badge {
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px 4px 6px;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-messages .list-group-item:hover {
  color: #e1000a;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-messages .list-group-item:hover i {
  transform: rotateY(-180deg);
  color: #e1000a;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-label {
  letter-spacing: 1px;
  color: #828D99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-labels .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-labels .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #e1000a;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-labels .list-group-item:last-child {
  margin-bottom: 0;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-labels .list-group-item.active {
  background-color: #ffffff;
  color: #e1000a;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-labels .list-group-item:hover {
  color: #e1000a;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-labels .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-labels .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-labels .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .list-group-labels .list-group-item:nth-child(5)::before {
  border-color: #707070;
}
.todo-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}
.todo-content-area .content-right .todo-list-wrapper .todo-list {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header {
  padding: 15px 20px;
  border-bottom: 1px solid #e8ebf1;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left {
  position: relative;
  top: 3px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .checkbox {
  position: relative;
  top: -2px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .btn-icon {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .btn-icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 47%;
  transform: translateY(-47%);
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .btn-icon:hover {
  color: #ffffff;
  background-color: #e1000a;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .btn-icon::after {
  display: none;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: unset !important;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .dropdown .dropdown-menu.show {
  margin-top: 45px;
  opacity: 1;
  visibility: visible;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .dropdown .dropdown-menu .dropdown-item i {
  color: #686868;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .dropdown .dropdown-menu .dropdown-item.active, .todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-left .dropdown .dropdown-menu .dropdown-item:hover i {
  transform: rotateY(-180deg);
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-right {
  position: relative;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-right label {
  position: absolute;
  left: 15px;
  color: #71738d;
  margin-bottom: 0;
  top: 14px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-right .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 2px 15px 0 39px;
  transition: 0.5s;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-right .form-control:focus {
  box-shadow: unset;
  background-color: #eef5f9;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-right .form-control:focus::placeholder {
  color: transparent;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .todo-list-header .header-right .form-control::placeholder {
  color: #a8a8a8;
  transition: 0.5s;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper {
  height: calc(100vh - 277px);
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item {
  position: relative;
  transition: 0.5s;
  border-bottom: 1px solid #e8ebf1;
  padding-left: 60px;
  padding-right: 110px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .checkbox {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .todo-item-title {
  position: relative;
  padding-left: 53px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .todo-item-title img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .todo-item-title h3 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .todo-item-title p {
  color: #919191;
  font-size: 15px;
  margin-bottom: 0;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .todo-item-action {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .todo-item-action a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 3px;
  background-color: #c6e8cd;
  color: #13bb37;
  text-align: center;
  margin-left: 3px;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .todo-item-action a:hover {
  background-color: #13bb37;
  color: #ffffff;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .todo-item-action a.delete {
  background-color: #f9d0d9 !important;
  color: #e1000a !important;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item .todo-item-action a.delete:hover {
  background-color: #e1000a !important;
  color: #ffffff !important;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:hover {
  box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(2) .todo-item-action a, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(6) .todo-item-action a, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(10) .todo-item-action a {
  background-color: #d1def5;
  color: #4788ff;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(2) .todo-item-action a:hover, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(6) .todo-item-action a:hover, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(10) .todo-item-action a:hover {
  background-color: #4788ff;
  color: #ffffff;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(3) .todo-item-action a, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(7) .todo-item-action a, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(11) .todo-item-action a {
  background-color: #f5d1c4;
  color: #fb4a06;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(3) .todo-item-action a:hover, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(7) .todo-item-action a:hover, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(11) .todo-item-action a:hover {
  background-color: #fb4a06;
  color: #ffffff;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(4) .todo-item-action a, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(8) .todo-item-action a, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(12) .todo-item-action a {
  background-color: #d1def5;
  color: #4788ff;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(4) .todo-item-action a:hover, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(8) .todo-item-action a:hover, .todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:nth-child(12) .todo-item-action a:hover {
  background-color: #4788ff;
  color: #ffffff;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper .todo-list-item:last-child {
  border-bottom: none;
}

/*================================================
App Calendar CSS
=================================================*/
.calendar-container {
  overflow: hidden;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  background: #ffffff;
}
.calendar-container .calendar {
  display: grid;
  width: 100%;
  overflow: auto;
  grid-template-columns: repeat(7, minmax(120px, 1fr));
  grid-template-rows: 50px;
  grid-auto-rows: 120px;
}
.calendar-container .calendar-header {
  position: relative;
  padding: 25px 20px;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
}
.calendar-container .calendar-header h3 {
  font-size: 18px;
  font-weight: 700;
}
.calendar-container .calendar-header button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: #e1000a;
  color: #ffffff;
  transition: 0.5s;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 15px;
  font-weight: 700;
}
.calendar-container .calendar-header button:hover {
  background-color: #2a2a2a;
  color: #ffffff;
}
.calendar-container .day {
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  border-right: 1px solid rgba(166, 168, 179, 0.12);
  text-align: right;
  padding: 14px 20px;
  letter-spacing: 1px;
  font-size: 12px;
  box-sizing: border-box;
  color: #898989;
  position: relative;
  pointer-events: none;
  z-index: 1;
}
.calendar-container .day:nth-of-type(7n + 7) {
  border-right: 0;
}
.calendar-container .day:nth-of-type(n + 1):nth-of-type(-n + 7) {
  grid-row: 2;
}
.calendar-container .day:nth-of-type(n + 8):nth-of-type(-n + 14) {
  grid-row: 3;
}
.calendar-container .day:nth-of-type(n + 15):nth-of-type(-n + 21) {
  grid-row: 4;
}
.calendar-container .day:nth-of-type(n + 22):nth-of-type(-n + 28) {
  grid-row: 5;
}
.calendar-container .day:nth-of-type(n + 29):nth-of-type(-n + 35) {
  grid-row: 6;
}
.calendar-container .day:nth-of-type(7n + 1) {
  grid-column: 1/1;
}
.calendar-container .day:nth-of-type(7n + 2) {
  grid-column: 2/2;
}
.calendar-container .day:nth-of-type(7n + 3) {
  grid-column: 3/3;
}
.calendar-container .day:nth-of-type(7n + 4) {
  grid-column: 4/4;
}
.calendar-container .day:nth-of-type(7n + 5) {
  grid-column: 5/5;
}
.calendar-container .day:nth-of-type(7n + 6) {
  grid-column: 6/6;
}
.calendar-container .day:nth-of-type(7n + 7) {
  grid-column: 7/7;
}
.calendar-container .day-name {
  color: #2a2a2a;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  line-height: 50px;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}
.calendar-container .day--disabled {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  cursor: not-allowed;
}
.calendar-container .task {
  border-left-width: 3px;
  padding: 8px 12px;
  margin: 10px;
  border-left-style: solid;
  font-size: 14px;
  position: relative;
}
.calendar-container .task.task--warning {
  border-left-color: #fdb44d;
  grid-column: 4/span 3;
  grid-row: 3;
  background: #fef0db;
  align-self: center;
  color: #fc9b10;
  margin-top: -5px;
}
.calendar-container .task.task--danger {
  border-left-color: #fa607e;
  grid-column: 2/span 3;
  grid-row: 3;
  margin-top: 15px;
  background: rgba(253, 197, 208, 0.7);
  align-self: end;
  color: #f8254e;
}
.calendar-container .task.task--info {
  border-left-color: #4786ff;
  grid-column: 6/span 2;
  grid-row: 5;
  margin-top: 15px;
  background: rgba(218, 231, 255, 0.7);
  align-self: end;
  color: #0a5eff;
}
.calendar-container .task.task--primary {
  background: #4786ff;
  border: 0;
  border-radius: 4px;
  grid-column: 3/span 3;
  grid-row: 4;
  align-self: end;
  color: #fff;
  box-shadow: 0 10px 14px rgba(71, 134, 255, 0.4);
}
.calendar-container .task .task__detail {
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  background: #ffffff;
  border: 1px solid rgba(166, 168, 179, 0.2);
  color: #000000;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  z-index: 2;
}
.calendar-container .task .task__detail::after, .calendar-container .task .task__detail::before {
  bottom: 100%;
  left: 30%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.calendar-container .task .task__detail::before {
  margin-left: -8px;
  border-bottom-color: rgba(166, 168, 179, 0.2);
  border-width: 8px;
}
.calendar-container .task .task__detail:after {
  margin-left: -6px;
  border-bottom-color: #ffffff;
  border-width: 6px;
}
.calendar-container .task .task__detail h4 {
  font-size: 15px;
  margin: 0;
  color: #51565d;
}
.calendar-container .task .task__detail p {
  margin-top: 4px;
  margin-bottom: 0;
  color: rgba(81, 86, 93, 0.7);
  font-weight: 500;
  font-size: 12px;
}

/*================================================
App Chat CSS
=================================================*/
.chat-content-area {
  margin-bottom: 30px;
}
.chat-content-area .sidebar-left {
  float: left;
}
.chat-content-area .sidebar-left .sidebar {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 300px;
}
.chat-content-area .sidebar-left .sidebar .chat-sidebar-header {
  padding-bottom: 25px;
}
.chat-content-area .sidebar-left .sidebar .chat-sidebar-header form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 15px;
  top: 11.3px;
  font-size: 18px;
  color: #a8a8a8;
}
.chat-content-area .sidebar-left .sidebar .chat-sidebar-header form .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 0 15px 0 40px;
  transition: 0.5s;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.chat-content-area .sidebar-left .sidebar .chat-sidebar-header form .form-control:focus {
  box-shadow: unset;
  background-color: #eef5f9;
}
.chat-content-area .sidebar-left .sidebar .chat-sidebar-header form .form-control:focus::placeholder {
  color: transparent !important;
}
.chat-content-area .sidebar-left .sidebar .chat-sidebar-header form .form-control::placeholder {
  color: #a8a8a8;
  transition: 0.5s;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content {
  height: calc(100vh - 321px);
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li {
  cursor: pointer;
  margin-bottom: 20px;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li:last-child {
  margin-bottom: 0;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li .avatar {
  position: relative;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li .avatar .status-busy {
  background-color: #FF5B5C;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li .avatar .status-online {
  background-color: #39DA8A;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li .avatar .status-away {
  background-color: #FDAC41;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li .avatar .status-offline {
  background-color: #475F7B;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li .avatar [class*=status-] {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 1px;
  top: 1px;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li h6 {
  margin-bottom: 3px;
  font-size: 16px;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-user li span {
  color: #71738d;
  font-size: 14.5px;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-label {
  color: #2a2a2a;
  margin-top: 25px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  font-size: 18px;
  font-weight: 700;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content .chat-menu .list-group-label::before {
  width: 70px;
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: #e1000a;
}
.chat-content-area .content-right {
  float: right;
  width: calc(100% - 330px);
}
.chat-content-area .content-right .chat-list-wrapper .chat-list {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .simplebar-scrollbar::before {
  background: #aaaaaa;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header {
  padding: 15px 20px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-left h6 {
  white-space: nowrap;
  font-size: 16px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-left .avatar {
  position: relative;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-left .avatar .status-busy {
  background-color: #FF5B5C;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-left .avatar .status-online {
  background-color: #39DA8A;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-left .avatar .status-away {
  background-color: #FDAC41;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-left .avatar .status-offline {
  background-color: #475F7B;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-left .avatar [class*=status-] {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 1px;
  top: 1px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right {
  position: relative;
  top: 1px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li {
  display: inline-block;
  margin-left: 1px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li:first-child {
  margin-left: 0;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .favorite {
  font-size: 20px;
  cursor: pointer;
  color: #C7CFD6;
  position: relative;
  top: -1px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .favorite.active {
  color: #FDAC41;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .favorite.active i::before {
  content: "\ed99";
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .dropdown .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727E8C;
  font-size: 22px;
  line-height: 15px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .dropdown .dropdown-toggle::after {
  display: none;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: unset !important;
  left: auto !important;
  right: 0;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .dropdown .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .dropdown .dropdown-menu .dropdown-item i {
  color: #686868;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .dropdown .dropdown-menu .dropdown-item.active, .chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-header .header-right ul li .dropdown .dropdown-menu .dropdown-item:hover i {
  transform: rotateY(-180deg);
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container {
  height: calc(100vh - 338px);
  background-color: #E3E6F1;
  padding: 25px 20px;
  background-image: url(../../assets/img/chat-bg.png);
  background-position: center center;
  background-size: contain;
  background-repeat: repeat;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content {
  text-align: center;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat .chat-avatar {
  float: right;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat .chat-body {
  overflow: hidden;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat .chat-body .chat-message {
  position: relative;
  float: right;
  max-width: 420px;
  text-align: left;
  padding: 12px 16px;
  margin: 10px 21.28px 21.28px 0;
  clear: both;
  word-break: break-word;
  color: #ffffff;
  background-color: #3578e5;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat .chat-body .chat-message p {
  color: #ffffff;
  margin-bottom: 0;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat .chat-body .chat-message p a {
  color: #ffffff !important;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat .chat-body .chat-message .time {
  position: absolute;
  bottom: -25px;
  right: 0;
  color: #6b7886;
  font-size: 0.8rem;
  white-space: nowrap;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat .chat-body .chat-message:first-child {
  margin-top: 0 !important;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat.chat-left .chat-avatar {
  float: left;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat.chat-left .chat-body .chat-message {
  text-align: left;
  float: left;
  margin: 10px 0 21.28px 21.28px;
  color: #727E8C;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat.chat-left .chat-body .chat-message p {
  color: #727E8C;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .chat.chat-left .chat-body .chat-message p a {
  color: #727E8C !important;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-container .chat-content .badge-light {
  padding: 9px 20px 7px;
  text-transform: uppercase;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer {
  background-color: #fafafa;
  padding: 15px 20px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer .emoji-btn {
  padding: 0;
  border: none;
  background-color: #f9d0d9;
  border-radius: 50%;
  width: 35px;
  font-size: 19px;
  line-height: 40px;
  height: 35px;
  color: #e1000a;
  transition: 0.5s;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer .emoji-btn:hover, .chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer .emoji-btn:focus {
  background-color: #e1000a;
  color: #ffffff;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer .file-attachment-btn {
  padding: 0;
  border: none;
  background-color: #f9d0d9;
  border-radius: 50%;
  width: 35px;
  font-size: 19px;
  line-height: 40px;
  height: 35px;
  color: #e1000a;
  transition: 0.5s;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer .file-attachment-btn:hover, .chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer .file-attachment-btn:focus {
  background-color: #e1000a;
  color: #ffffff;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer form {
  position: relative;
  padding-right: 128px;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer form .form-control {
  background-color: #ffffff;
  height: 45px;
  border-radius: 30px;
  transition: 0.5s;
  padding-left: 15px;
  font-size: 14.5px;
  border: 1px solid #DFE3E7;
  color: #2a2a2a;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer form .form-control:focus {
  border-color: #e1000a;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer form .send-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: #e1000a;
  color: #ffffff;
  transition: 0.5s;
  border-radius: 30px;
  height: 45px;
  padding: 0 30px;
  box-shadow: 0 4px 12px 0 rgba(255, 0, 10, 0.2);
  font-size: 15px;
  font-weight: 700;
}
.chat-content-area .content-right .chat-list-wrapper .chat-list .chat-list-footer form .send-btn:hover {
  box-shadow: 0 4px 12px 0 rgba(255, 0, 10, 0.6);
}

/*================================================
Profile CSS
=================================================*/
.profile-header {
  margin-top: 20px;
  border-radius: 5px;
  position: relative;
}
.profile-header .user-profile-images {
  position: relative;
}
.profile-header .user-profile-images .cover-image {
  border-radius: 5px;
}
.profile-header .user-profile-images .profile-image {
  position: absolute;
  left: 25px;
  bottom: -70px;
}
.profile-header .user-profile-images .profile-image img {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  border: 5px solid #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.41);
}
.profile-header .user-profile-images .profile-image .upload-profile-photo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 1;
}
.profile-header .user-profile-images .profile-image .upload-profile-photo a {
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}
.profile-header .user-profile-images .profile-image .upload-profile-photo a i {
  font-size: 25px;
  color: #ffffff;
  margin-bottom: 2px;
  display: block;
}
.profile-header .user-profile-images .profile-image:hover .upload-profile-photo {
  opacity: 1;
  visibility: visible;
}
.profile-header .user-profile-images .upload-cover-photo {
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}
.profile-header .user-profile-images .upload-cover-photo a {
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}
.profile-header .user-profile-images .upload-cover-photo a i {
  font-size: 25px;
  color: #ffffff;
  position: relative;
  top: 5px;
  margin-right: 2px;
}
.profile-header .user-profile-images .upload-cover-photo a span {
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.profile-header .user-profile-images .user-profile-text {
  position: absolute;
  left: 220px;
  z-index: 1;
  bottom: 15px;
}
.profile-header .user-profile-images .user-profile-text h3 {
  margin-bottom: 3px;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}
.profile-header .user-profile-images .user-profile-text span {
  color: #ffffff;
  opacity: 0.9;
  font-size: 15px;
  font-weight: 600;
}
.profile-header .user-profile-images:hover .upload-cover-photo a span {
  opacity: 1;
  visibility: visible;
}
.profile-header .user-profile-nav {
  padding: 25px 25px 25px 220px;
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.profile-header .user-profile-nav .nav {
  padding-left: 0;
  border: none;
  margin-bottom: 0;
  list-style-type: none;
}
.profile-header .user-profile-nav .nav .nav-item {
  margin-right: 10px;
}
.profile-header .user-profile-nav .nav .nav-item .nav-link {
  color: #475F7B;
  border-radius: 5px;
  transition: 0.5s;
  text-decoration: none;
  background-color: #ffffff;
  background-color: #f7f7f7;
  font-size: 15px;
  font-weight: 700;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.profile-header .user-profile-nav .nav .nav-item .nav-link.active, .profile-header .user-profile-nav .nav .nav-item .nav-link:hover {
  color: #ffffff;
  background-color: #e1000a;
}

.user-intro-box .card-body {
  text-align: center;
}
.user-intro-box .card-body button {
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: #f5f6f7;
  transition: 0.5s;
  border: 1px solid #ccd0d5;
  color: #4b4f56;
  margin-top: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.user-intro-box .card-body button:hover {
  background-color: #e1000a;
  color: #ffffff;
  border-color: #e1000a;
}

.user-info-box .card-body ul li {
  color: #1d2129;
  margin-bottom: 10px;
}
.user-info-box .card-body ul li:last-child {
  margin-bottom: 0;
}
.user-info-box .card-body ul li span {
  line-height: 1.6;
}
.user-info-box .card-body ul li i {
  font-size: 22px;
  color: #616770;
  transition: 0.5s;
}
.user-info-box .card-body ul li a {
  color: #385898;
  text-decoration: none;
}
.user-info-box .card-body ul li:hover i {
  transform: rotateY(-180deg);
  color: #e1000a;
}
.user-info-box .card-body button {
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: #f5f6f7;
  transition: 0.5s;
  border: 1px solid #ccd0d5;
  color: #4b4f56;
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.user-info-box .card-body button:hover {
  background-color: #e1000a;
  color: #ffffff;
  border-color: #e1000a;
}

.user-photos-box .card-header .photo-upload-btn {
  display: inline-block;
  border: 1px solid #afb8bd;
  color: #2a2a2a;
  border-radius: 5px;
  background-color: transparent;
  text-decoration: none;
  padding: 3px 12px;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 600;
}
.user-photos-box .card-header .photo-upload-btn:hover {
  background-color: #e1000a;
  color: #ffffff;
  border-color: #e1000a;
}
.user-photos-box .card-body ul {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.user-photos-box .card-body ul li {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.user-photos-box .card-body ul li a {
  display: block;
}

.user-skills-box .card-header .create-btn {
  display: inline-block;
  border: none;
  color: #385898;
  background-color: transparent;
  text-decoration: none;
  padding: 0;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 600;
}
.user-skills-box .card-header .create-btn:hover {
  text-decoration: underline;
}
.user-skills-box .card-body {
  margin-bottom: -3px;
}
.user-skills-box .card-body .badge {
  background-color: #f9ecef;
  padding: 5px 8px 4px;
  color: #e1000a;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 3px;
  margin-right: 1px;
}
.user-skills-box .card-body .badge:hover {
  color: #ffffff;
  background-color: #e1000a;
}

.user-social-box .card-header .add-btn {
  display: inline-block;
  border: none;
  color: #385898;
  background-color: transparent;
  text-decoration: none;
  padding: 0;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 600;
}
.user-social-box .card-header .add-btn:hover {
  text-decoration: underline;
}
.user-social-box .card-body {
  margin-bottom: -4px;
}
.user-social-box .card-body a {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 40px;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 20px;
  margin-right: 8px;
  margin-bottom: 4px;
  text-align: center;
  text-decoration: none;
}
.user-social-box .card-body a.facebook {
  background-color: #3b5998;
  color: #ffffff;
}
.user-social-box .card-body a.twitter {
  background-color: #1da1f2;
  color: #ffffff;
}
.user-social-box .card-body a.instagram {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  color: #ffffff;
}
.user-social-box .card-body a.linkedin {
  background-color: #0077b5;
  color: #ffffff;
}
.user-social-box .card-body a.pinterest {
  background-color: #bd081c;
  color: #ffffff;
}
.user-social-box .card-body a.stack-overflow {
  background-color: #f48024;
  color: #ffffff;
}

.timeline-story-content .timeline-story-header .info h6 {
  color: #616770;
  margin-bottom: 3px;
}
.timeline-story-content .timeline-story-header .info h6 a {
  display: inline-block;
  color: #2a2a2a;
  font-weight: 700;
}
.timeline-story-content .timeline-story-header .info span {
  font-size: 14px;
  color: #616770;
}
.timeline-story-content .card-body p {
  color: #727E8C;
}
.timeline-story-content .card-body iframe {
  width: 100%;
  height: 400px;
  border: none;
}
.timeline-story-content .feedback-summary a {
  text-decoration: none;
  color: #686c71;
  margin-right: 15px;
  padding-right: 10px;
  border-right: 1px solid #eeeeee;
}
.timeline-story-content .feedback-summary a i {
  font-size: 20px;
  position: relative;
  top: 3px;
  margin-right: 3px;
}
.timeline-story-content .feedback-summary a:hover {
  color: #e1000a;
}
.timeline-story-content .feedback-summary a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.user-events-box .card-body ul {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.user-events-box .card-body ul li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.user-events-box .card-body ul li a {
  color: #616770;
  display: block;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 25px 10px;
  text-align: center;
  text-decoration: none;
}
.user-events-box .card-body ul li a i {
  margin-bottom: 10px;
  background: #3578e5;
  display: inline-block;
  color: #ffffff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  font-size: 20px;
}
.user-events-box .card-body ul li a span {
  display: block;
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}
.user-events-box .card-body ul li a:hover {
  border-color: #e1000a;
}

.user-friend-request-box .card-body ul li {
  margin-bottom: 20px;
}
.user-friend-request-box .card-body ul li:last-child {
  margin-bottom: 0;
}
.user-friend-request-box .card-body ul li h6 {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}
.user-friend-request-box .card-body ul li h6 a {
  color: #2a2a2a;
}
.user-friend-request-box .card-body ul li span {
  color: #616770;
  font-size: 14px;
}
.user-friend-request-box .card-body ul li span a {
  color: #616770;
}
.user-friend-request-box .card-body ul li .btn-box {
  margin-top: 5px;
}
.user-friend-request-box .card-body ul li .btn-box a {
  text-decoration: none;
  border: 1px solid #eeeeee;
  color: #616770;
  font-size: 18px;
  display: inline-block;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 30px;
  text-align: center;
}
.user-friend-request-box .card-body ul li .btn-box a:hover {
  color: #ffffff;
  border-color: #e1000a;
  background-color: #e1000a;
}

.user-trends-box .card-body ul li a {
  text-decoration: none;
  color: #616770;
  font-size: 14px;
  display: block;
  border-bottom: 1px solid #eeeeee;
  padding-top: 10px;
  padding-bottom: 10px;
}
.user-trends-box .card-body ul li a span {
  display: block;
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 2px;
}
.user-trends-box .card-body ul li:first-child a {
  border-top: 1px solid #eeeeee;
}

.post-share-content {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 25px;
}
.post-share-content .post-share-field {
  position: relative;
}
.post-share-content .post-share-field label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.post-share-content .post-share-field label img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.post-share-content .post-share-field .form-control {
  border: none;
  border-radius: 0;
  padding: 20px 0 0 75px;
}
.post-share-content .post-share-footer {
  border-top: 1px solid #eeeeee;
}
.post-share-content .post-share-footer .post-share-info a {
  font-size: 25px;
  color: #828D99;
  margin-right: 10px;
  line-height: 1;
  text-decoration: none;
}
.post-share-content .post-share-footer .post-share-info a:last-child {
  margin-right: 0;
}
.post-share-content .post-share-footer .post-share-info a:hover {
  color: #e1000a;
}
.post-share-content .post-share-footer .post-btn a {
  color: #2a2a2a;
  text-decoration: none;
  border-radius: 5px;
  padding: 11px 35px 9px;
  background-color: #E2ECFF;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.post-share-content .post-share-footer .post-btn a:hover {
  color: #ffffff;
  background-color: #e1000a;
}

.user-about-me-box p {
  color: #727E8C;
  margin-bottom: 10px;
}

.user-biography-box p {
  color: #727E8C;
  margin-bottom: 10px;
}

.user-friends-box {
  padding-bottom: 10px;
}
.user-friends-box .single-friends-box {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  margin-bottom: 15px;
  width: 100%;
}
.user-friends-box .single-friends-box img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.user-friends-box .single-friends-box .info h5 {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}
.user-friends-box .single-friends-box .info h5 a {
  color: #2a2a2a;
}
.user-friends-box .single-friends-box .info span {
  color: #616770;
  font-size: 14px;
}
.user-friends-box .single-friends-box .info span a {
  color: #616770;
}

.user-settings-box h3 {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 700;
}
.user-settings-box h3 i {
  width: 35px;
  height: 35px;
  line-height: 36px;
  border-radius: 50%;
  text-align: center;
  background-color: #E2ECFF;
  font-size: 20px;
  margin-right: 5px;
  transition: 0.5s;
  position: relative;
  top: 2px;
}
.user-settings-box h3:hover i {
  color: #ffffff;
  background-color: #e1000a;
}
.user-settings-box h3:not(:first-child) {
  margin-top: 10px;
}
.user-settings-box form .form-group {
  margin-bottom: 20px;
}
.user-settings-box form .form-group label {
  color: #6c757d;
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
}
.user-settings-box form .form-group .form-control {
  padding-left: 15px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  color: #2a2a2a;
  font-size: 14px;
  height: 44px;
  transition: 0.5s;
}
.user-settings-box form .form-group .form-control::placeholder {
  color: #6c757d;
}
.user-settings-box form .form-group .form-control:focus {
  border-color: #b1bbc4;
}
.user-settings-box form .form-group textarea.form-control {
  height: auto;
  padding-top: 15px;
}
.user-settings-box form .btn-box {
  margin-top: 10px;
}
.user-settings-box form .btn-box .submit-btn {
  border-radius: 5px;
  background-color: #e1000a;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 30px;
  border: none;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 700;
}
.user-settings-box form .btn-box .submit-btn i {
  font-size: 20px;
  line-height: 1;
  margin-right: 2px;
  position: relative;
  top: 3px;
}
.user-settings-box form .btn-box .submit-btn:hover {
  opacity: 0.8;
}

/*================================================
Gallery CSS
=================================================*/
.single-gallery-image img {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

/*================================================
Login CSS
=================================================*/
.login-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.login-area.bg-image {
  background-image: url(../../assets/img/login-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.login-area .d-table {
  width: 100%;
  height: 100%;
}
.login-area .d-table-cell {
  vertical-align: middle;
}
.login-area .login-form {
  max-width: 480px;
  /* background: $white-color;*/
  background: rgba(255, 255, 255, 0.8);
  /* border-radius: 5px;*/
  overflow: hidden;
  text-align: center;
  padding: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  /*  box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);*/
  margin-left: auto;
  margin-right: auto;
}
.login-area .login-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.login-area .login-form form {
  margin-top: 25px;
}
.login-area .login-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.login-area .login-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.login-area .login-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.login-area .login-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: 0.5s;
  background: #e1000a;
}
.login-area .login-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  transition: 0.5s;
  font-family: "Nunito", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.login-area .login-form form .form-group .form-control::placeholder {
  color: #A1A1A1;
  transition: 0.5s;
}
.login-area .login-form form .form-group .form-control:focus {
  padding-left: 5px;
}
.login-area .login-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.login-area .login-form form .form-group .form-control:focus + .label-title::before {
  width: 100%;
}
.login-area .login-form form .form-group .form-control:focus + .label-title {
  color: #A1A1A1;
}
.login-area .login-form form .form-group .form-control:focus + .label-title i {
  top: -22px;
}
.login-area .login-form form .form-group .remember-forgot {
  text-align: left;
  position: relative;
  overflow: hidden;
}
.login-area .login-form form .form-group .remember-forgot .forgot-password {
  float: right;
  color: #0ca7ec;
  text-decoration: none !important;
}
.login-area .login-form form .form-group .checkbox-box {
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
  padding-left: 25px;
  color: #6c757d;
}
.login-area .login-form form .form-group .checkbox-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.login-area .login-form form .form-group .checkbox-box input:checked ~ .checkmark {
  background-color: #2196F3;
}
.login-area .login-form form .form-group .checkbox-box input:checked ~ .checkmark:after {
  display: block;
}
.login-area .login-form form .form-group .checkbox-box .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  overflow: hidden;
  background-color: #eeeeee;
  transition: 0.5s;
}
.login-area .login-form form .form-group .checkbox-box .checkmark:after {
  content: "\e9f0";
  position: absolute;
  display: none;
  left: 0;
  top: -4.5px;
  color: #ffffff;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 17px;
  font-variant: normal;
}
.login-area .login-form form .form-group .checkbox-box a {
  text-decoration: none;
  color: #2a2a2a;
}
.login-area .login-form form .login-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.login-area .login-form form .login-btn:hover, .login-area .login-form form .login-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}
.login-area .login-form form p {
  margin-top: 20px;
  color: #6c757d;
}
.login-area .login-form form p a {
  text-decoration: none !important;
  color: #0ca7ec;
}

/*================================================
Register CSS
=================================================*/
.register-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.register-area.bg-image {
  background-image: url(../../assets/img/register-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.register-area .d-table {
  width: 100%;
  height: 100%;
}
.register-area .d-table-cell {
  vertical-align: middle;
}
.register-area .register-form {
  max-width: 480px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.register-area .register-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.register-area .register-form form {
  margin-top: 25px;
}
.register-area .register-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.register-area .register-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.register-area .register-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.register-area .register-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: 0.5s;
  background: #e1000a;
}
.register-area .register-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  transition: 0.5s;
  font-family: "Nunito", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.register-area .register-form form .form-group .form-control::placeholder {
  color: #A1A1A1;
  transition: 0.5s;
}
.register-area .register-form form .form-group .form-control:focus {
  padding-left: 5px;
}
.register-area .register-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.register-area .register-form form .form-group .form-control:focus + .label-title::before {
  width: 100%;
}
.register-area .register-form form .form-group .form-control:focus + .label-title {
  color: #A1A1A1;
}
.register-area .register-form form .form-group .form-control:focus + .label-title i {
  top: -22px;
}
.register-area .register-form form .form-group .terms-conditions {
  text-align: left;
  position: relative;
  overflow: hidden;
}
.register-area .register-form form .form-group .checkbox-box {
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
  padding-left: 25px;
  color: #6c757d;
}
.register-area .register-form form .form-group .checkbox-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.register-area .register-form form .form-group .checkbox-box input:checked ~ .checkmark {
  background-color: #2196F3;
}
.register-area .register-form form .form-group .checkbox-box input:checked ~ .checkmark:after {
  display: block;
}
.register-area .register-form form .form-group .checkbox-box .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  overflow: hidden;
  background-color: #eeeeee;
  transition: 0.5s;
}
.register-area .register-form form .form-group .checkbox-box .checkmark:after {
  content: "\e9f0";
  position: absolute;
  display: none;
  left: 0;
  top: -4.5px;
  color: #ffffff;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 17px;
  font-variant: normal;
}
.register-area .register-form form .form-group .checkbox-box a {
  text-decoration: none;
  color: #2a2a2a;
}
.register-area .register-form form .register-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.register-area .register-form form .register-btn:hover, .register-area .register-form form .register-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}
.register-area .register-form form p {
  margin-top: 20px;
  color: #6c757d;
}
.register-area .register-form form p a {
  text-decoration: none !important;
  color: #0ca7ec;
}

/*================================================
Reset Password CSS
=================================================*/
.reset-password-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.reset-password-area.bg-image {
  background-image: url(../../assets/img/reset-password-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.reset-password-area .d-table {
  width: 100%;
  height: 100%;
}
.reset-password-area .d-table-cell {
  vertical-align: middle;
}
.reset-password-area .reset-password-content {
  max-width: 900px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 60px 50px 30px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.reset-password-area .reset-password-content .image {
  text-align: center;
}
.reset-password-area .reset-password-content .reset-password-form {
  padding-left: 30px;
}
.reset-password-area .reset-password-content .reset-password-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.reset-password-area .reset-password-content .reset-password-form form {
  margin-top: 25px;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: 0.5s;
  background: #e1000a;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  transition: 0.5s;
  font-family: "Nunito", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .form-control::placeholder {
  color: #A1A1A1;
  transition: 0.5s;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .form-control:focus {
  padding-left: 5px;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .form-control:focus + .label-title::before {
  width: 100%;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .form-control:focus + .label-title {
  color: #A1A1A1;
}
.reset-password-area .reset-password-content .reset-password-form form .form-group .form-control:focus + .label-title i {
  top: -22px;
}
.reset-password-area .reset-password-content .reset-password-form form .reset-password-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.reset-password-area .reset-password-content .reset-password-form form .reset-password-btn:hover, .reset-password-area .reset-password-content .reset-password-form form .reset-password-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Forgot Password CSS
=================================================*/
.forgot-password-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.forgot-password-area.bg-image {
  background-image: url(../../assets/img/forgot-password-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.forgot-password-area .d-table {
  width: 100%;
  height: 100%;
}
.forgot-password-area .d-table-cell {
  vertical-align: middle;
}
.forgot-password-area .forgot-password-content {
  max-width: 900px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 60px 50px 30px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.forgot-password-area .forgot-password-content .image {
  text-align: center;
}
.forgot-password-area .forgot-password-content .forgot-password-form {
  padding-left: 30px;
}
.forgot-password-area .forgot-password-content .forgot-password-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.forgot-password-area .forgot-password-content .forgot-password-form p {
  color: #6c757d;
  margin-top: 7px;
}
.forgot-password-area .forgot-password-content .forgot-password-form form {
  margin-top: 25px;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: 0.5s;
  background: #e1000a;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  transition: 0.5s;
  font-family: "Nunito", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .form-control::placeholder {
  color: #A1A1A1;
  transition: 0.5s;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .form-control:focus {
  padding-left: 5px;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .form-control:focus + .label-title::before {
  width: 100%;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .form-control:focus + .label-title {
  color: #A1A1A1;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .form-group .form-control:focus + .label-title i {
  top: -22px;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .forgot-password-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.forgot-password-area .forgot-password-content .forgot-password-form form .forgot-password-btn:hover, .forgot-password-area .forgot-password-content .forgot-password-form form .forgot-password-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Lock Screen CSS
=================================================*/
.lock-screen-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.lock-screen-area.bg-image {
  background-image: url(../../assets/img/lock-screen-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.lock-screen-area .d-table {
  width: 100%;
  height: 100%;
}
.lock-screen-area .d-table-cell {
  vertical-align: middle;
}
.lock-screen-area .lock-screen-content {
  max-width: 900px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 60px 50px 30px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.lock-screen-area .lock-screen-content .image {
  text-align: center;
}
.lock-screen-area .lock-screen-content .lock-screen-form {
  padding-left: 30px;
}
.lock-screen-area .lock-screen-content .lock-screen-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.lock-screen-area .lock-screen-content .lock-screen-form form {
  margin-top: 25px;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: 0.5s;
  background: #e1000a;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  transition: 0.5s;
  font-family: "Nunito", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .form-control::placeholder {
  color: #A1A1A1;
  transition: 0.5s;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .form-control:focus {
  padding-left: 5px;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .form-control:focus + .label-title::before {
  width: 100%;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .form-control:focus + .label-title {
  color: #A1A1A1;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group .form-control:focus + .label-title i {
  top: -22px;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .lock-screen-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .lock-screen-btn:hover, .lock-screen-area .lock-screen-content .lock-screen-form form .lock-screen-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Error 404 CSS
=================================================*/
.error-404-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.error-404-area.bg-image {
  background-image: url(../../assets/img/error-404-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.error-404-area .d-table {
  width: 100%;
  height: 100%;
}
.error-404-area .d-table-cell {
  vertical-align: middle;
}
.error-404-area .notfound {
  position: relative;
  max-width: 520px;
  z-index: 1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.error-404-area .notfound .notfound-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.error-404-area .notfound .notfound-bg div {
  width: 100%;
  background: #ffffff;
  border-radius: 90px;
  height: 125px;
}
.error-404-area .notfound .notfound-bg div:nth-child(1) {
  box-shadow: 5px 5px 0 0 #f3f3f3;
}
.error-404-area .notfound .notfound-bg div:nth-child(2) {
  transform: scale(1.3);
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 10;
}
.error-404-area .notfound .notfound-bg div:nth-child(3) {
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 90;
}
.error-404-area .notfound h1 {
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 5px;
  background: url(../../assets/img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}
.error-404-area .notfound h3 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 25px;
}
.error-404-area .notfound p {
  color: #6084a4;
  margin-bottom: 20px;
  line-height: 1.7;
}
.error-404-area .notfound .default-btn {
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.error-404-area .notfound .default-btn:hover, .error-404-area .notfound .default-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Error 500 CSS
=================================================*/
.error-500-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.error-500-area.bg-image {
  background-image: url(../../assets/img/error-500-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.error-500-area .d-table {
  width: 100%;
  height: 100%;
}
.error-500-area .d-table-cell {
  vertical-align: middle;
}
.error-500-area .notfound {
  position: relative;
  max-width: 520px;
  z-index: 1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.error-500-area .notfound .notfound-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.error-500-area .notfound .notfound-bg div {
  width: 100%;
  background: #ffffff;
  border-radius: 90px;
  height: 125px;
}
.error-500-area .notfound .notfound-bg div:nth-child(1) {
  box-shadow: 5px 5px 0 0 #f3f3f3;
}
.error-500-area .notfound .notfound-bg div:nth-child(2) {
  transform: scale(1.3);
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 10;
}
.error-500-area .notfound .notfound-bg div:nth-child(3) {
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 90;
}
.error-500-area .notfound h1 {
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 5px;
  background: url(../../assets/img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}
.error-500-area .notfound h3 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 25px;
}
.error-500-area .notfound p {
  color: #6084a4;
  margin-bottom: 20px;
  line-height: 1.7;
}
.error-500-area .notfound .default-btn {
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.error-500-area .notfound .default-btn:hover, .error-500-area .notfound .default-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Coming Soon CSS
=================================================*/
.coming-soon-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.coming-soon-area.bg-image {
  background-image: url(../../assets/img/coming-soon-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.coming-soon-area .d-table {
  width: 100%;
  height: 100%;
}
.coming-soon-area .d-table-cell {
  vertical-align: middle;
}
.coming-soon-area .coming-soon-content {
  max-width: 850px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px 60px 50px 30px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.coming-soon-area .coming-soon-content .logo {
  display: inline-block;
}
.coming-soon-area .coming-soon-content h2 {
  font-size: 35px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.coming-soon-area .coming-soon-content #timer {
  margin-top: 50px;
}
.coming-soon-area .coming-soon-content #timer div {
  background-color: #333333;
  color: #ffffff;
  width: 100px;
  height: 110px;
  border-radius: 5px;
  font-size: 35px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
}
.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  margin-top: -4px;
  font-size: 15px;
  font-weight: 500;
}
.coming-soon-area .coming-soon-content form {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.coming-soon-area .coming-soon-content form .form-group {
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}
.coming-soon-area .coming-soon-content form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.coming-soon-area .coming-soon-content form .form-group .label-title i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.coming-soon-area .coming-soon-content form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: 0.5s;
  background: #e1000a;
}
.coming-soon-area .coming-soon-content form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  transition: 0.5s;
  font-family: "Nunito", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.coming-soon-area .coming-soon-content form .form-group .form-control::placeholder {
  color: #A1A1A1;
  transition: 0.5s;
}
.coming-soon-area .coming-soon-content form .form-group .form-control:focus {
  padding-left: 5px;
}
.coming-soon-area .coming-soon-content form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.coming-soon-area .coming-soon-content form .form-group .form-control:focus + .label-title::before {
  width: 100%;
}
.coming-soon-area .coming-soon-content form .form-group .form-control:focus + .label-title {
  color: #A1A1A1;
}
.coming-soon-area .coming-soon-content form .form-group .form-control:focus + .label-title i {
  top: -22px;
}
.coming-soon-area .coming-soon-content form .default-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.coming-soon-area .coming-soon-content form .default-btn:hover, .coming-soon-area .coming-soon-content form .default-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content form p {
  color: #6084a4;
  line-height: 1.7;
  margin-bottom: 0;
  margin-top: 20px;
}

/*================================================
Maintenance CSS
=================================================*/
.maintenance-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.maintenance-area.bg-image {
  background-image: url(../../assets/img/maintenance-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.maintenance-area .d-table {
  width: 100%;
  height: 100%;
}
.maintenance-area .d-table-cell {
  vertical-align: middle;
}
.maintenance-area .maintenance-content {
  max-width: 850px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 40px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.maintenance-area .maintenance-content .logo {
  display: inline-block;
}
.maintenance-area .maintenance-content h2 {
  background: url(../../assets/img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 5px;
}
.maintenance-area .maintenance-content p {
  color: #6084a4;
  line-height: 1.7;
  font-size: 17px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 0;
}
.maintenance-area .maintenance-content .default-btn {
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.maintenance-area .maintenance-content .default-btn:hover, .maintenance-area .maintenance-content .default-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Not Authorized CSS
=================================================*/
.not-authorized-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.not-authorized-area.bg-image {
  background-image: url(../../assets/img/not-authorized-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.not-authorized-area .d-table {
  width: 100%;
  height: 100%;
}
.not-authorized-area .d-table-cell {
  vertical-align: middle;
}
.not-authorized-area .not-authorized-content {
  max-width: 850px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px 60px 50px 30px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.not-authorized-area .not-authorized-content .logo {
  display: inline-block;
}
.not-authorized-area .not-authorized-content h2 {
  background: url(../../assets/img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 5px;
}
.not-authorized-area .not-authorized-content p {
  color: #6084a4;
  line-height: 1.7;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 0;
}
.not-authorized-area .not-authorized-content .default-btn {
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.not-authorized-area .not-authorized-content .default-btn:hover, .not-authorized-area .not-authorized-content .default-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
FAQ CSS
=================================================*/
.faq-search {
  text-align: center;
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 1px 1px;
}
.faq-search h2 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 700;
}
.faq-search form {
  max-width: 600px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 20px;
}
.faq-search form .form-control {
  background-color: #ffffff;
  color: #2a2a2a;
  border: none;
  transition: 0.5s;
  border-radius: 30px;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 55px;
  font-size: 16px;
  padding-left: 20px;
}
.faq-search form .form-control::placeholder {
  transition: 0.5s;
}
.faq-search form .form-control:focus::placeholder {
  color: transparent;
}
.faq-search form button {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  text-transform: uppercase;
  border-radius: 30px;
  transition: 0.5s;
  padding: 12px 30px 11px;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.faq-search form button:hover {
  opacity: 0.5;
}
.faq-search p {
  margin-bottom: 0;
  color: #6084a4;
}

.faq-area {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 35px 25px;
}
.faq-area .title {
  margin-bottom: 50px;
  text-align: center;
}
.faq-area .title h2 {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
}
.faq-area .title p {
  color: #6084a4;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.faq-accordion-tab .tabs {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 50px;
  text-align: center;
}
.faq-accordion-tab .tabs li {
  margin-left: 10px;
  margin-right: 10px;
}
.faq-accordion-tab .tabs li a {
  color: #2a2a2a;
  text-decoration: none;
  border-radius: 5px;
  padding: 20px;
  display: block;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  font-size: 15px;
  font-weight: 700;
}
.faq-accordion-tab .tabs li a i {
  font-size: 30px;
}
.faq-accordion-tab .tabs li a span {
  display: block;
  margin-top: 5px;
}
.faq-accordion-tab .tabs li a:hover {
  background-color: #e1000a;
  color: #ffffff;
}
.faq-accordion-tab .tabs li.current a {
  background-color: #e1000a;
  color: #ffffff;
}

.faq-accordion {
  padding-left: 20px;
  padding-right: 20px;
}
.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item {
  display: block;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 10px;
}
.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-title {
  padding: 15px 20px 10px 51px;
  color: #2a2a2a;
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 700;
}
.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #e1000a;
  color: #ffffff;
  font-size: 22px;
}
.faq-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.faq-accordion .accordion .accordion-title.active i::before {
  content: "\eaf8";
}
.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  color: #727E8C;
  padding: 15px 15px;
  border-top: 1px solid #eeeeee;
}
.faq-accordion .accordion .accordion-content.show {
  display: block;
}

.tab_content .tabs_item {
  display: none;
}
.tab_content .tabs_item:first-child {
  display: block;
}

/*================================================
Pricing CSS
=================================================*/
.single-pricing-box {
  margin-bottom: 30px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
  padding: 40px 30px 28px 30px;
  border-radius: 5px;
  transition: 0.5s;
}
.single-pricing-box .pricing-header {
  position: relative;
  z-index: 1;
  background-color: #e1000a;
  margin: -40px -30px 30px -30px;
  border-radius: 5px 5px 0 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 25px;
}
.single-pricing-box .pricing-header h3 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.single-pricing-box .price {
  color: #2a2a2a;
  line-height: 50px;
  align-items: baseline;
  position: relative;
  z-index: 1;
  font-size: 40px;
  font-weight: 700;
}
.single-pricing-box .price sub {
  line-height: 16px;
  color: #6084a4;
  margin: 0 -8px 0 -9px;
  position: relative;
  top: -2px;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
}
.single-pricing-box .price-features-list {
  text-align: left;
  padding: 30px 30px;
  margin: 25px -30px 30px -30px;
  list-style-type: none;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
}
.single-pricing-box .price-features-list li {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 25px;
  color: #6084a4;
  font-weight: 500;
  font-size: 16px;
}
.single-pricing-box .price-features-list li i {
  font-size: 20px;
  color: #e1000a;
  position: absolute;
  right: 0;
  top: 10px;
}
.single-pricing-box .price-features-list li i.bx-check-double {
  color: #6ac04f;
}
.single-pricing-box .price-features-list li i.bx-x {
  color: #dd5252;
}
.single-pricing-box .get-started-btn {
  display: inline-block;
  color: #e1000a;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}
.single-pricing-box .get-started-btn::before {
  width: 0;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #e1000a;
  transition: 0.5s;
}
.single-pricing-box .get-started-btn:hover::before {
  width: 100%;
}
.single-pricing-box.red .pricing-header {
  background-color: #fc6765;
}
.single-pricing-box.red .get-started-btn {
  color: #fc6765;
}
.single-pricing-box.red .get-started-btn::before {
  background-color: #fc6765;
}
.single-pricing-box.orange .pricing-header {
  background-color: #ffa143;
}
.single-pricing-box.orange .get-started-btn {
  color: #ffa143;
}
.single-pricing-box.orange .get-started-btn::before {
  background-color: #ffa143;
}
.single-pricing-box:hover {
  transform: translateY(-10px);
}

.item_box {
  width: 100%;
  border: 1px solid #dee2e6;
  box-shadow: inset 0 -9px 30px 16px rgba(231, 156, 156, 0.07);
  padding: 6px;
  margin-top: 5px;
}

.item_box1 {
  width: 100%;
  border: 1px solid #dee2e6;
  box-shadow: inset 0 -9px 30px 16px rgba(250, 229, 234, 0.07);
  padding: 6px;
  margin-top: 10px;
}

/*================================================
Invoice CSS
=================================================*/
.invoice-area {
  background-color: #ffffff;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 25px;
}

.invoice-header h3 {
  font-size: 25px;
  font-weight: 700;
}
.invoice-header p {
  font-weight: 600;
  font-size: 15px;
}

.invoice-middle h4 {
  font-size: 18px;
  font-weight: 700;
}
.invoice-middle h5 {
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 700;
}
.invoice-middle h5 sub {
  margin-left: 50px;
  bottom: 0;
  width: 90px;
  display: inline-block;
  color: #6084a4;
  font-weight: 500;
  font-size: 15px;
}
.invoice-middle span {
  font-weight: 600;
  font-size: 15px;
}

.invoice-table table {
  margin-bottom: 0;
}
.invoice-table table thead th {
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
  font-size: 15px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.invoice-table table tbody td {
  vertical-align: middle;
  color: #6084a4;
  border-width: 1px;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.invoice-table table tbody td.total, .invoice-table table tbody td.total-price {
  color: #2a2a2a;
  font-size: 17px;
}

.form-table table {
  margin-bottom: 0;
}
.form-table table thead th {
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
  font-size: 15px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.form-table table tbody td {
  vertical-align: middle;
  color: #6084a4;
  border-width: 1px;
  border: none !important;
  padding: 3px !important;
  border-top: none !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.form-table table tbody td.total, .form-table table tbody td.total-price {
  color: #2a2a2a;
  font-size: 17px;
}

.invoice-btn-box .default-btn {
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 700;
}
.invoice-btn-box .default-btn:hover, .invoice-btn-box .default-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}
.invoice-btn-box .optional-btn {
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #3578e5;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}
.invoice-btn-box .optional-btn:hover, .invoice-btn-box .optional-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Timeline CSS
=================================================*/
.timeline {
  position: relative;
}
.timeline::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 100%;
  background-color: #e1000a;
  left: 14px;
  top: 5px;
  border-radius: 4px;
}
.timeline .timeline-month {
  position: relative;
  padding: 4px 15px 4px 35px;
  background-color: #e1000a;
  display: inline-block;
  border-radius: 30px;
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: 700;
}
.timeline .timeline-month::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 100%;
  left: 5px;
  top: 5px;
}
.timeline .timeline-section {
  padding-left: 35px;
  display: block;
  position: relative;
  margin-bottom: 30px;
}
.timeline .timeline-section .timeline-date {
  margin-bottom: 15px;
  padding: 3px 15px;
  background: #3578e5;
  position: relative;
  display: inline-block;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 600;
}
.timeline .timeline-section::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 1px;
  background-color: #3578e5;
  top: 12px;
  left: 20px;
}
.timeline .timeline-section::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #3578e5;
  top: 7px;
  left: 11px;
  border-radius: 50%;
}
.timeline .timeline-section .col-lg-4 {
  margin-bottom: 15px;
}
.timeline .timeline-section .timeline-box {
  position: relative;
  background-color: #ffffff;
  transition: all 0.3s ease;
  overflow: hidden;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 15px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}
.timeline .timeline-section .timeline-box .box-title {
  padding: 10px 15px;
  font-weight: 600;
}
.timeline .timeline-section .timeline-box .box-title i {
  margin-right: 2px;
  font-size: 18px;
  position: relative;
  top: 2px;
}
.timeline .timeline-section .timeline-box .box-content {
  padding: 15px;
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
}
.timeline .timeline-section .timeline-box .box-content .box-item {
  margin-bottom: 10px;
  color: #606060;
  font-size: 14px;
}
.timeline .timeline-section .timeline-box .box-content .box-item:last-child {
  margin-bottom: 0;
}
.timeline .timeline-section .timeline-box .box-content .box-item strong {
  color: #2a2a2a;
  font-style: italic;
}
.timeline .timeline-section .timeline-box .box-footer {
  padding: 10px 15px;
  background-color: #2a2a2a;
  text-align: right;
  font-style: italic;
  color: #ffffff;
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
  padding-bottom: 25px;
  border-top: 1px solid #eeeeee;
  padding-top: 25px;
}
.footer-area p {
  font-size: 15px;
  margin-bottom: 0;
  color: #686868;
}
.footer-area p i {
  position: relative;
  top: 2px;
}
.footer-area p a {
  display: inline-block;
  color: #686868;
}
.footer-area p a:hover {
  color: #e1000a;
  text-decoration: none;
}

.bottommenu-area {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  transition: 0.5s;
}
.bottommenu-area .sidemenu-body {
  box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.1);
  background: #ffffff;
  overflow: hidden;
  display: none;
}
.bottommenu-area .sidemenu-body .sidemenu-nav {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item-title {
  color: #d69191;
  display: block;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item-title:not(:first-child) {
  margin-top: 20px;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link {
  color: #7e7e7e;
  padding: 2px 20px;
  position: relative;
  z-index: 1;
  font-size: 12px;
  font-weight: 600;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .icon {
  color: #2a2a2a;
  transition: 0.5s;
  display: inline-block;
  margin-right: 0px;
  font-size: 16px;
  position: relative;
  top: 6px;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .badge {
  background-color: #43d39e;
  color: #ffffff;
  transition: 0.5s;
  margin-bottom: 7px;
  position: relative;
  top: -1px;
  padding: 4px 7px 4px 6px;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.disabled {
  color: #a3a3a3;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.disabled i {
  color: #a3a3a3;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  transition: 0.5s;
  background: #e1000a;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.collapsed-nav-link {
  position: relative;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.collapsed-nav-link::after {
  content: "\e9f9";
  position: absolute;
  right: 15px;
  top: 9px;
  color: #7e7e7e;
  transition: 0.5s;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover {
  background-color: #f9ecef;
  color: #e1000a;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover::before {
  width: 0px;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover .icon {
  transform: rotateY(-180deg);
  color: #e1000a;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link, .bottommenu-area .sidemenu-body .sidemenu-nav .nav-item.active .nav-link {
  background-color: #f9ecef;
  color: #e1000a;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link::before, .bottommenu-area .sidemenu-body .sidemenu-nav .nav-item.active .nav-link::before {
  width: 0px;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link .icon, .bottommenu-area .sidemenu-body .sidemenu-nav .nav-item.active .nav-link .icon {
  color: #e1000a;
}
.bottommenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link.collapsed-nav-link::after, .bottommenu-area .sidemenu-body .sidemenu-nav .nav-item.active .nav-link.collapsed-nav-link::after {
  transform: rotate(-90deg);
}
.bottommenu-area.toggle-sidemenu-area {
  width: 55px;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-header {
  padding: 0 10px;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-header .navbar-brand {
  display: block !important;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-header .navbar-brand span {
  opacity: 0;
  visibility: hidden;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-header .burger-menu {
  opacity: 0;
  visibility: hidden;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item-title {
  display: none;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .menu-title {
  display: none;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link::after {
  display: none;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .badge {
  display: none;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level {
  padding-left: 20px;
}
.bottommenu-area.toggle-sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level {
  padding-left: 0;
}
.bottommenu-area:hover.toggle-sidemenu-area {
  width: 250px;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-header {
  padding: 0 20px;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-header .navbar-brand {
  display: flex !important;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-header .navbar-brand span {
  opacity: 1;
  visibility: visible;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-header .burger-menu {
  opacity: 1;
  visibility: visible;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item-title {
  display: block;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .nav-link .menu-title {
  display: inline-block;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .nav-link::after {
  display: block;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .nav-link .badge {
  display: inline-block;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .sidemenu-nav-second-level {
  padding-left: 30px;
}
.bottommenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item .sidemenu-nav-second-level .nav-item .sidemenu-nav-third-level {
  padding-left: 15px;
}

.tabs-header {
  float: left;
  width: 100%;
  position: relative;
  background: #fff;
  overflow-x: auto;
}
.tabs-header ul {
  margin: 0;
  padding-left: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
}
.tabs-header ul li {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  min-width: max-content;
  padding: 0;
  height: 43px;
  display: table;
  padding-top: 11px;
  float: left;
  border-bottom: 1px solid #fff;
}
.tabs-header ul li a {
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  color: inherit;
}
.tabs-header ul li a:hover {
  text-decoration: none;
}
.tabs-header ul li .block {
  border-radius: 5px;
  background-color: #f5f6f7;
  border: 1px solid #ccd0d5;
  color: #4b4f56;
  padding: 5px 7px;
  height: 30px;
  margin-left: 7px;
}
.tabs-header ul li:hover {
  color: #e1000a;
  transition: all 0.1s ease-in-out;
  border-bottom: 2px solid #e1000a;
}
.tabs-header ul .active {
  color: #e1000a;
  transition: all 0.1s ease-in-out;
  border-bottom: 2px solid #e1000a;
}

.cart-scroll {
  float: left;
  width: 100%;
  position: relative;
  background: #fff;
  overflow-x: auto;
}
.cart-scroll ul {
  margin: 0;
  padding-left: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
}
.cart-scroll ul li {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  min-width: max-content;
  padding: 0;
  display: table;
  padding-top: 11px;
  float: left;
  border-bottom: 1px solid #fff;
}
.cart-scroll ul li a {
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
}
.cart-scroll ul li .block {
  border-radius: 5px;
  background-color: #f5f6f7;
  border: 1px solid #ccd0d5;
  color: #4b4f56;
  padding: 5px 7px;
  height: 30px;
  margin-left: 7px;
}

.single-row-block {
  float: left;
  width: 100%;
  position: relative;
  background: #fff;
  overflow-x: auto;
}
.single-row-block ul {
  margin: 0;
  padding-left: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
}
.single-row-block ul li {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  min-width: max-content;
  padding: 0;
  height: 43px;
  display: table;
  padding-top: 11px;
  float: left;
  border-bottom: 1px solid #fff;
}
.single-row-block ul li a {
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
}
.single-row-block ul li a:hover {
  color: #e1000a;
  transition: all 0.1s ease-in-out;
}
.single-row-block ul li .active {
  color: #e1000a;
  transition: all 0.1s ease-in-out;
}
.single-row-block ul li .block {
  border-radius: 5px;
  background-color: #f5f6f7;
  border: 1px solid #ccd0d5;
  padding: 5px 7px;
  height: 30px;
  margin-left: 7px;
}
.single-row-block ul li:hover {
  color: #e1000a;
  transition: all 0.1s ease-in-out;
}
.single-row-block ul .active {
  color: #e1000a;
  transition: all 0.1s ease-in-out;
}

.tabs-header-block {
  float: left;
  width: 100%;
  position: relative;
  background: #fff;
  overflow-x: auto;
}
.tabs-header-block ul {
  margin: 0;
  padding-left: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
}
.tabs-header-block ul li {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  min-width: max-content;
  padding: 0;
  height: 54px;
  display: inline-block;
  padding-top: 11px;
  float: left;
  border-bottom: 1px solid #fff;
}
.tabs-header-block ul li a {
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
}
.tabs-header-block ul li a:hover {
  color: #e1000a;
  transition: all 0.1s ease-in-out;
}
.tabs-header-block ul li .active {
  color: #e1000a;
  transition: all 0.1s ease-in-out;
}
.tabs-header-block ul li .block {
  border-radius: 20px;
  background-color: #f5f6f7;
  border: 1px solid #ccd0d5;
  padding: 5px 12px;
  height: 30px;
  margin-left: 7px;
}
.tabs-header-block ul li a:hover {
  background-color: #696767;
  color: #ffffff;
  transition: all 0.1s ease-in-out;
}
.tabs-header-block ul li .active {
  background-color: #696767;
  color: #ffffff;
  transition: all 0.1s ease-in-out;
}

.mt {
  margin-top: -8px !important;
}

.mbutton {
  display: inline-flex;
  background-color: #ee4141 !important;
  text-align: center !important;
  border-radius: 30px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  color: white !important;
  margin-top: 5px !important;
  padding: 7px !important;
}
.mbutton span {
  font-size: 15px;
  padding-top: 3px;
  padding-right: 5px;
}

.abutton {
  display: inline-flex;
  background-color: #3C8DBC !important;
  text-align: center !important;
  border-radius: 30px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  color: white !important;
  margin-top: 5px !important;
  padding: 7px !important;
}
.abutton span {
  font-size: 15px;
  padding-top: 3px;
  padding-right: 5px;
}

body .ui-table .ui-table-tbody > tr > td {
  padding: none;
}

.p-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.search-bar {
  height: 55px;
  width: 100%;
  background-color: white;
  box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.07);
  background: #fff !important;
  -webkit-animation: 0.1s ease-in-out fadeInRight;
  animation: 0.1s ease-in-out fadeInRight;
}

.sicon {
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 0px;
  padding-right: 5px;
  cursor: pointer;
}

.home {
  padding: 20px;
  text-align: center;
  min-height: 200px;
  border: 1px;
  cursor: pointer;
}
.home a:hover {
  text-decoration: none;
}
.home .mbox {
  height: 150px;
  box-shadow: inset 0px 1px 3px 2px rgba(13, 32, 109, 0.05);
  background-color: #fff;
}
.home .mbox .ibox {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #e1000a;
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.15);
  text-align: center;
  font-size: 38px;
  color: #fff;
  position: absolute;
  left: 35%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 40%;
}
.home .mbox .ibox li {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.home .mbox:nth-child(2) .ibox {
  background-color: #13bb37;
  box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
}
.home .mbox:nth-child(3) .ibox {
  background-color: #ff4b00;
  box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
}
.home .mbox:nth-child(4) .ibox {
  background-color: #4788ff;
  box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
}
.home .mboxx {
  height: 150px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  background: hsla(0, 0%, 100%, 0.8);
}
.home .mboxx .li {
  position: absolute;
  color: rgba(204, 193, 193, 0.7098039216);
  bottom: 0px;
  left: 0px;
  padding: 10px;
  font-weight: 200;
  font-size: 16px;
}
.home .mboxx .amt {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 5px;
  padding-right: 10px;
  font-size: 13px;
  font-weight: 400;
  color: #d20f8d;
}
.home .mboxx .iboxx {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgba(224, 186, 186, 0.2196078431);
  /* box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.15); */
  text-align: center;
  font-size: 38px;
  color: #ee4141;
  position: absolute;
  left: 35%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 40%;
}
.home .mboxx .iboxx li {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.home span {
  font-size: 17;
  font-weight: 600;
  color: rgba(42, 42, 42, 0.8705882353);
  font-weight: 500;
  position: absolute;
  left: 0;
  padding: 5px;
}

.prb5 {
  padding-right: 5px !important;
  padding-bottom: 5px !important;
}

.vin_dia_bk {
  background-size: cover;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999999;
  bottom: 0px;
  background-color: rgba(33, 37, 41, 0.4117647059);
  padding-top: 15%;
}

.vin_dia {
  background: rgba(255, 255, 255, 0.8);
  /* border-radius: 5px; */
  overflow: hidden;
  text-align: center;
  padding: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  /* box-shadow: 1px 5px 24px 0 rgb(68 102 242 / 5%); */
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.vin_cross {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #d9232d;
  color: #fff;
}

.vin_diag_title {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}

.fsize {
  font-size: 12px !important;
}

.per_tex {
  text-align: right;
  border-radius: 0px;
  width: 80px !important;
  background-color: inherit;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.txt_right_p {
  padding-right: 16px;
}

.per_tex_w {
  text-align: right;
  width: 80px !important;
}

.amt_tex {
  text-align: right;
  border-radius: 0px;
  background-color: inherit;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 800;
}

.amt_lbl {
  text-align: right;
  min-width: 100px;
  border-radius: 0px;
  background-color: inherit;
  background-clip: padding-box;
  font-weight: 600;
  color: #023552;
  padding-left: 10px;
}

.sum_bg {
  background-color: #f8eeee;
}

.vingrid_tb .tr {
  background-color: whitesmoke;
}
.vingrid_tb > :nth-child(odd) {
  background-color: white;
}
.vingrid_tb > :nth-child(even) {
  background-color: whitesmoke;
}

.trh {
  height: 28px;
}

.rmin_height {
  min-height: 320px;
}

.vinledger_tb .tr {
  background-color: rgb(252, 247, 247);
}
.vinledger_tb > :nth-child(odd) {
  background-color: white;
}
.vinledger_tb > :nth-child(even) {
  background-color: rgb(252, 247, 247);
}

.Net {
  color: #ea575d;
  font-weight: 800;
  font-family: monospace;
  font-size: 35px;
}

.Net_lb {
  color: #ea575d;
  font-weight: 700;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 25px;
  padding-left: 10px;
}

.nb_style {
  border-style: none;
  text-align: right;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 800;
}

.sum_text {
  border-style: none;
  text-align: right;
  background: none;
  border-color: dimgray;
  font-weight: 700;
}

.itex {
  width: 44px;
  line-height: 16px;
  border-radius: 0px;
  border: #dee2e6;
  border-style: solid;
  border-width: 1px;
  padding: 0px;
  text-align: center;
}

.pb5 {
  padding-bottom: 5px !important;
}

.Net_Amt {
  color: blue;
  font-weight: 800;
  font-size: 23px;
}

.stock {
  color: rgb(221, 16, 16) !important;
  font-weight: 800;
  font-size: 13px;
}

.search-bar_Ext {
  width: 350px;
  top: 70px;
  right: 85px;
  position: fixed;
  z-index: 995;
  display: inline-flex;
  background-color: inherit;
}
.search-bar_Ext input {
  margin: 5px !important;
  height: 31px !important;
}

.disp {
  display: block;
}

.cm_top {
  position: relative;
  text-align: center;
  top: -52px;
  z-index: 999999;
  font-size: 20px;
  font-weight: 900;
  color: #e1000a !important;
}

.search_Footer {
  bottom: 0;
  width: 100%;
  display: inline-flex;
  text-align: center;
}
.search_Footer .buttons {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  width: 50%;
  cursor: pointer;
}
.search_Footer .buttons li {
  top: 3px;
  font-size: 20px;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
}

.disp_none {
  display: none !important;
}

.search-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fafafa;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-radius: 0.25rem;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}

.hbar {
  color: #2a2a2a;
  padding-top: 10px;
  padding-bottom: 5px;
  position: relative;
  border-bottom: 1px solid #eee;
  font-size: 18px;
  font-weight: 700;
  background-color: white;
}
.hbar::before {
  width: 120px;
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: #e1000a;
}

.hbar1 {
  color: #2a2a2a;
  padding-top: 10px;
  padding-bottom: 5px;
  position: relative;
  border-bottom: 1px solid #eee;
  font-size: 18px;
  font-weight: 700;
  background-color: white;
}
.hbar1::before {
  width: 120px;
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: #e1000a;
}

.Cart-Card {
  box-shadow: 0 0 3px rgba(230, 106, 106, 0.1);
}

.imbar {
  color: black;
}

.timg {
  cursor: pointer;
  padding: 10px;
}

.hb_title {
  color: #2a2a2a !important;
  font-size: 18px;
  font-weight: 700;
}

.add_tr {
  padding: 5px;
  cursor: pointer;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.add_trr {
  padding: 0px !important;
  width: 20px !important;
}

.search-bal-li {
  width: 40px;
  font-size: 27px;
  font-weight: 400;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  color: gray;
}

.Cart-Card {
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  margin: 2px;
  height: auto;
  text-align: center;
}
.Cart-Card img {
  height: 150px;
  width: 150px;
  padding: 10px;
}
.Cart-Card .pro-content {
  height: 80px;
}
.Cart-Card .pro-content .prod-Name {
  color: #005b9f;
  font-size: 15px;
  font-weight: 700;
  margin-left: 0;
  text-align: left;
}
.Cart-Card .pro-content .desc {
  color: #a3a3a3;
  font-size: 12px;
  margin-left: 0;
  text-align: left;
}
.Cart-Card .mrp {
  color: #a3a3a3;
  font-size: 14px;
  margin-left: 0;
  text-align: left;
  margin-bottom: 5px;
}
.Cart-Card .mrp .ma {
  text-decoration: line-through;
  padding-right: 10px;
}
.Cart-Card .mrp .pro {
  padding-left: 3px;
  color: #10b759;
  font-weight: 800;
}
.Cart-Card .price {
  color: rgba(220, 107, 9, 0.8784313725);
  font-size: 15px;
  font-weight: 700;
  margin-left: 0;
  text-align: left;
  margin-bottom: 5px;
}
.Cart-Card .price table {
  width: 100%;
}
.Cart-Card .btn_add {
  color: #FF5B5C;
  font-size: 15px;
  font-weight: 700;
  margin-right: 0px;
  text-align: left;
  margin-bottom: 0px;
}

.product-head {
  padding: 5px;
  width: 100%;
  background-color: #fff;
  margin-right: 2px;
  margin-left: 2px;
}
.product-head table {
  width: 100%;
}
.product-head table .head {
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 700;
}
.product-head table .text-sm {
  font-size: 14px;
}
.product-head table .go-back {
  color: #202223;
  font-size: 30px;
  line-height: 0;
  position: relative;
  top: 2px;
}
.product-head table .dropdown .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727E8C;
  font-size: 22px;
  line-height: 15px;
}
.product-head table .dropdown .dropdown-toggle::after {
  display: none;
}
.product-head table .dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: unset !important;
  left: auto !important;
  right: 0;
}
.product-head table .dropdown .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}
.product-head table .dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.product-head table .dropdown .dropdown-menu .dropdown-item i {
  color: #686868;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.product-head table .dropdown .dropdown-menu .dropdown-item.active, .product-head table .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.product-head table .dropdown .dropdown-menu .dropdown-item:hover i {
  transform: rotateY(-180deg);
}

.group_head {
  background-color: #f5f6f7;
  padding: 5px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}
.group_head .text {
  color: #616770;
  font-weight: 500;
}
.group_head .b-btn {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  margin-top: 1px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  position: absolute;
  background-color: #f9ecef;
  padding: 5px 8px 4px;
  color: #e1000a;
  right: 0;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 3px;
  margin-right: 10px;
}
.group_head .b-btn:hover {
  color: #ffffff;
  background-color: #e1000a;
}

.companys {
  background-color: white;
}
.companys table {
  width: 100%;
}
.companys table .img {
  position: relative;
  left: 0;
  top: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.companys table .img-body {
  padding-left: 10px;
  padding-left: 10px;
  width: 65px;
}
.companys table .head {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
}
.companys table .sub-head {
  color: #919191;
  font-size: 15px;
  line-height: 0.9;
}
.companys table .sub-head1 {
  color: #919191;
  font-size: 14px;
}
.companys table td {
  padding-top: 10px;
  padding-bottom: 5px;
}
.companys table .right-bar {
  width: 40px;
  text-align: center;
  cursor: pointer;
}
.companys table .stars {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  color: #C7CFD6;
  margin-top: 8px;
  margin-bottom: -5px;
}
.companys table .bar {
  border-bottom: 1px solid #e8ebf1;
}

.head_text {
  font-weight: 600;
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.mpx {
  padding-left: 5px;
  padding-right: 5px;
}

.cart-view {
  width: 100%;
  background-color: #ffffff;
}
.cart-view table {
  width: 100%;
}
.cart-view table tr td {
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
.cart-view table .name {
  color: #2a2a2a;
  font-size: 13px;
  font-weight: 600;
  margin-left: 0;
  text-align: left;
  padding-top: 10px;
  line-height: 1.9;
}
.cart-view table .desc {
  color: #a3a3a3;
  font-size: 12px;
  margin-left: 0;
  text-align: left;
  line-height: 1.9;
}
.cart-view table .mrp {
  color: #a3a3a3;
  font-size: 12px;
}
.cart-view table .mrp .ma {
  text-decoration: line-through;
}
.cart-view table .del {
  color: #a3a3a3;
  font-size: 17px;
  display: inline-flex;
  align-items: center;
}
.cart-view table .del .text {
  font-size: 14px;
  padding-top: 5px;
  padding-left: 2px;
}
.cart-view table .price {
  font-size: 15px;
  font-weight: 700;
  margin-left: 0;
  padding-top: 10px;
  line-height: 1.9;
}
.cart-view table .qty {
  display: inline-flex;
  padding-top: 7px;
  /* justify-content: right; */
  align-items: center;
}
.cart-view table .qty .icons {
  font-size: 28px;
  color: #e4484f;
}
.cart-view table .qty .text {
  font-size: 17px;
  padding-left: 8px;
  padding-right: 8px;
}
.cart-view table .btn_add {
  color: #FF5B5C;
  font-size: 15px;
  font-weight: 700;
  margin-right: 0px;
  text-align: left;
  margin-bottom: 0px;
}

.cart-summary {
  width: 100%;
  background-color: #ffffff;
}
.cart-summary table {
  width: 100%;
}
.cart-summary table tr td {
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
.cart-summary table .name {
  color: #555555;
  font-size: 14px;
  font-weight: 500;
  margin-left: 0;
  text-align: left;
}
.cart-summary table .price {
  font-size: 15px;
  font-weight: 700;
  margin-left: 0;
  padding-top: 10px;
  line-height: 1.9;
}

.qty {
  display: inline-flex;
  padding-top: 7px;
  /* justify-content: right; */
  align-items: center;
}
.qty .icons {
  font-size: 28px;
  color: #e4484f;
}
.qty .text {
  font-size: 17px;
  padding-left: 8px;
  padding-right: 8px;
}

.maintenance-content {
  max-width: 850px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 40px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.maintenance-content .logo {
  display: inline-block;
}
.maintenance-content h2 {
  background: url(../../assets/img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 5px;
}
.maintenance-content p {
  color: #6084a4;
  line-height: 1.7;
  font-size: 17px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 0;
}
.maintenance-content .default-btn {
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #e1000a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.maintenance-content .default-btn:hover, .maintenance-content .default-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

.spin_block {
  background-color: #fff;
  top: 0;
  padding-top: 20%;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
  z-index: 999999;
}

.text_label {
  border: none;
  padding: 0px;
  margin: 0px;
  text-align: right;
  height: 30px;
}

.dropdown .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727E8C;
  font-size: 22px;
  line-height: 15px;
}
.dropdown .dropdown-toggle::after {
  display: none;
}
.dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: unset !important;
  left: auto !important;
  right: 0;
}
.dropdown .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}
.dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.dropdown .dropdown-menu .dropdown-item i {
  color: #686868;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.dropdown .dropdown-menu .dropdown-item.active, .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.dropdown .dropdown-menu .dropdown-item:hover i {
  transform: rotateY(-180deg);
}

.x {
  font-size: 17px;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 0px;
  cursor: pointer;
  color: #727E8C !important;
}

.right-corner {
  right: 0px;
  position: absolute;
  line-height: 40px;
  font-size: 26px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 3px;
  z-index: 99999;
  background-color: white;
}

.form-control input {
  border: 0;
  border-radius: 0;
  width: 100%;
}

.vin-form input {
  border-radius: 0;
  border: 0;
  background-color: inherit;
  width: 100%;
}
.vin-form input:focus {
  background-color: whitesmoke;
}

.row_hover :hover {
  background-color: #ffffff;
}

.del_bx {
  width: 40px;
  text-align: center;
  font-weight: 900;
  font-size: 17px;
  color: #da3e3e;
  cursor: pointer;
  vertical-align: middle;
  padding: 3px;
}
.del_bx li:hover {
  background-color: #e1000a;
  color: #ffffff;
  border-radius: 50%;
  height: 25px;
  padding-top: 4px;
  width: 25px;
}

.ptable {
  background-color: rgb(199, 245, 214);
  width: 100%;
  text-align: center;
  line-height: 80px;
  font-weight: 800;
  font-size: 20px;
  cursor: pointer;
}

.sbox {
  padding-top: 8px;
  padding-bottom: 8px;
  padding: 5px;
  background-color: white;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
}

.btn_refresh {
  position: absolute;
  right: 0;
  font-size: 26px;
  color: darkslateblue;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 5px;
  cursor: pointer;
}

.add_bx {
  width: 40px;
  text-align: center;
  font-weight: 900;
  font-size: 17px;
  color: #0d0f92;
  cursor: pointer;
  vertical-align: middle;
  padding: 3px;
}
.add_bx li:hover {
  background-color: #0d0f92;
  color: #ffffff;
  border-radius: 50%;
  height: 25px;
  padding-top: 4px;
  width: 25px;
}
.add_bx li:focus {
  background-color: #0d0f92;
  color: #ffffff;
  border-radius: 50%;
  height: 25px;
  padding-top: 4px;
  width: 25px;
}

.sum_row {
  width: 100%;
  background-color: #f5f3f3;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.sum_row .left {
  font-weight: 700;
  padding-left: 10px;
}
.sum_row .right {
  text-align: right;
  font-weight: 800;
  padding-right: 50px;
}

.entry_table .header {
  display: inline-flex;
  text-align: center;
  line-height: 35px;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333;
  background-color: #f4f4f4 !important;
}
.entry_table .header div {
  background-color: inherit;
  padding: 1px;
  border-left: 1px solid #c8c8c8;
  transition: box-shadow 0.2s;
}
.entry_table .header > :nth-child(1) {
  border-left: 0px solid #c8c8c8 !important;
}
.entry_table .header > :nth-child(1) {
  position: absolute;
}
.entry_table .header > :nth-child(2) {
  position: absolute;
  left: 40px;
}
.entry_table .header > :nth-child(3) {
  margin-left: 240px;
}
.entry_table .body {
  display: inline-flex;
  border-right: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
}
.entry_table .body div {
  border-left: 1px solid #c8c8c8;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.entry_table .body .cell .tbtext {
  border: 0;
  border-radius: 0;
  width: 100%;
  background-color: inherit;
}
.entry_table .body > :nth-child(1) {
  background-color: rgb(245, 243, 243);
  position: absolute;
  pointer-events: none;
}
.entry_table .body > :nth-child(2) {
  background-color: rgb(245, 243, 243);
  position: absolute;
  left: 40px;
  pointer-events: none;
}
.entry_table .body > :nth-child(3) {
  margin-left: 240px;
}
.entry_table > :nth-child(odd) {
  background-color: rgb(245, 243, 243);
}
.entry_table > :nth-child(even) {
  background-color: #fff;
}

.entry_table1 .header {
  display: inline-flex;
  text-align: center;
  line-height: 30px;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333;
  background-color: #f4f4f4 !important;
  width: 100%;
}
.entry_table1 .header div {
  background-color: inherit;
  padding: 1px;
  border-left: 1px solid #c8c8c8;
  transition: box-shadow 0.2s;
}
.entry_table1 .header > :nth-child(1) {
  border-left: 0px solid #c8c8c8 !important;
}
.entry_table1 .body {
  display: inline-flex;
  border-right: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  width: 100%;
  padding: 5px;
  padding-bottom: 2px;
}
.entry_table1 .body .cell .tbtext {
  border: 0;
  border-radius: 0;
  width: 100%;
  background-color: inherit;
}
.entry_table1 > :nth-child(odd) {
  background-color: rgb(245, 243, 243);
}
.entry_table1 > :nth-child(even) {
  background-color: #fff;
}

.vin_ledg {
  z-index: 247;
  min-height: 290px;
  background-color: white;
  top: 38px;
  position: absolute;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
}

.vin_select {
  border-radius: 0px;
  font-size: 11px;
  border: 0px;
  height: 30px;
  color: rgb(24, 24, 24);
  background: transparent;
  appearance: none;
  padding: 2px;
  outline: none !important;
  border-color: none !important;
  height: 20px;
}

.card-headbg {
  margin: -20px !important;
  padding: 10px !important;
  background-color: #eae9e9 !important;
}

.tday {
  display: inline-block !important;
  border: 1px solid #afb8bd !important;
  color: #2a2a2a !important;
  border-radius: 5px !important;
  background-color: transparent !important;
  text-decoration: none !important;
  padding: 3px 12px !important;
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.rselected {
  background-color: #0077B5 !important;
  color: white !important;
}

.pright15 {
  position: absolute;
  right: 15px;
}

.pright45 {
  position: absolute;
  right: 45px;
}

.pright70 {
  position: absolute;
  right: 75px;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.trhover :hover {
  color: white !important;
  background-color: #0077B5 !important;
  cursor: pointer;
}

.txt_left {
  line-height: 35px;
  width: 100px;
  text-align: left;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.911);
}

.right_shade {
  box-shadow: 0 16px 24px -12px rgba(0, 0, 0, 0.56) !important;
}

.nav_image {
  background-image: url("../../assets/img/Side.jpg") !important;
}

.nav_back {
  background-color: rgba(255, 255, 255, 0.9490196078);
}

.vcard {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px !important;
  color: #333;
  background: #fff;
  width: 100%;
  padding: 0px !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.vcard .card-header {
  margin: 0 15px;
  padding: 0 !important;
  position: relative;
  text-align: right;
  background-color: transparent;
  border: none;
}

.card-icon {
  border-radius: 3px;
  background-color: #999;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
}
.card-icon i {
  font-size: 36px;
  line-height: 56px;
  width: 56px;
  height: 56px;
  text-align: center;
  color: white;
}

.card-category {
  padding-top: 10px;
  color: #999;
  font-size: 16px;
}

.card-footer {
  padding: 0;
  padding-top: 10px;
  margin: 0 15px 10px;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eee;
  margin-top: 20px;
  background-color: transparent !important;
}

.card-title {
  margin-top: 15px;
  color: #3c4858;
  font-size: 18px;
  font-weight: 700;
}

.gshade {
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}

.oshade {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
  background: linear-gradient(60deg, #ffa726, #fb8c00);
}

.bshade {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
  background: linear-gradient(60deg, #26c6da, #00acc1);
}

.vshade {
  background: linear-gradient(60deg, #6a3c88, #54198a);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(133, 49, 171, 0.4);
}