/* You can add global styles to this file, and also import other style files */


$font-family: 'Nunito', sans-serif;
$main-color: #e1000a;
$back-color:#f9ecef;
$black-color: #2a2a2a;

$white-color: #ffffff;
$font-size: 15px;
$transition: .5s;

//$main-color: #0bf53e;
//$back-color:#edf9ec;


$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1550px;



/*dropdown-css*/
.search{

    table{
background-color: white;

    }
tr{
    .txt_icon
    {
       
        text-align: center;
    vertical-align: middle;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-weight: 900;
    font-size: 20px;
    padding-top: 6px;
    background-color: #e1000a;
    color: #f3f2f2;

 
    }
    &:nth-child(2) {
        .txt_icon {
            background-color: #727cf5;
                color: $white-color;
        }
    }
    &:nth-child(3) {
        .txt_icon {
            
            background-color: #10b759;
            color: $white-color;
        }
        
    }
    &:nth-child(4) {
        .txt_icon {
            background: rgba(255, 51, 102, .1);
            color: #f36;
        }
        
    }
    &:nth-child(5) {
        .txt_icon {
            background: rgba(251, 188, 6, .1);
            color: #fbbc06;
        }

    }
}

    .txt_name {
    margin-bottom: 1px;
    padding-top: 2px;
    font-weight: 600;
    color: #2a2a2a;
    line-height: 1.4;
    font-family: Nunito, sans-serif;
    }

    .sub_txt
    {
        color: #919191;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.4;
    }


}

@media only #{$media} and ($feature_max : $value_two) {



    .search-bar{
        position: fixed;
        top: 0px;
        z-index: 10000;
    }
    
.add_icon
{
 
font-size: 18px;

}

    .addbtn
    {

        width: 45px!important;
        height: 45px!important;
        background-color: #fbd8d7!important;
        color: #e1000a!important;
        border-radius: 50%!important;
        -webkit-transition: .5s!important;
        transition: .5s!important;
        font-size: 22px!important;
        position: fixed!important;
        bottom: 10px!important;
        right: 10px!important;;
        z-index: 9999;
        i{

            position: absolute!important;;
            left: 10px;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            margin-left: auto;
            margin-right: auto;
        }
    }


    .addbtn1
    {

        width: 45px!important;
        height: 45px!important;
        background-color: #fbd8d7!important;
        color: #e1000a!important;
        border-radius: 50%!important;
        -webkit-transition: .5s!important;
        transition: .5s!important;
        font-size: 22px!important;
        position: fixed!important;
        bottom: 45px!important;
        right: 10px!important;;
        z-index: 9999;
        i{

            position: absolute!important;;
            left: 10px;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            margin-left: auto;
            margin-right: auto;
        }
    }

}